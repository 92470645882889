import ChatWs from "../../libs/ChatWs";
import { Debug } from "../../libs/logger";
import PrivateMessage from "../../libs/PrivateMessage";

const CHATWS_RECALL = 60000
const CHATWS_RECONNECT_PERIODE = 60000 * 10; // in ms, 60000 = 1m

export const __ChatWs__ = function (_this) {
    _this.privateMessage = new PrivateMessage();
    _this.chatWsLastRegisteredTs = 0;
    _this.chatWsAge = Date.now();

    this.chatMessageHandler = (msg) => {
        let listMessage = msg.message;
        if (listMessage.length <= 0) {
            Debug("message zero, ignore")
            return;
        } else {
            Debug(`got message, listMessage=${listMessage}`);
        }

        for (let m in listMessage) {
            let message = listMessage[m]

            // insert to chat window
            let msg = message;
            if (msg.text.includes("[cmd]")) {
                if (msg.text === "[cmd]chatbox_is_shown") {
                    // send confirmation
                    if (_this.chatWs) {
                        _this.chatWs.sendChatMessageReceived(
                            _this.context.profile.id,
                            message.from_id,
                            message.ts);
                    }

                    _this.messageEvent.setChatBoxIsOpen(msg.from_id.includes("proctor") ? msg.participantId : msg.from_id, true)
                }
            } else {
                let dx = _this.privateMessage.chatInsert(
                    _this.context.profile.id,
                    msg.from_id.includes("proctor") ? msg.participantId : msg.from_id,
                    msg.ts,
                    msg.text,
                    false,
                    msg.from_id.includes("proctor") ? msg.from_id : null)
                if (_this.state.chatPageEnterId !== msg.from_id ||
                    _this.state.showParticipantDesk === false
                ) {
                    _this.messageEvent.setNewMessage(msg.from_id.includes("proctor") ? msg.participantId : msg.from_id, true);
                } else {
                }

                if (_this.state.chatPageEnterId === msg.from_id &&
                    _this.state.showParticipantDesk === true
                ) {
                    _this.setState({
                        privateMessage: dx
                    })
                }

                _this.setState({
                    privateProctorerId: _this.context.profile.id,
                    helpAlert: false,
                });

                if (_this.chatWs) {
                    _this.chatWs.sendChatMessageReceived(
                        _this.context.profile.id,
                        message.from_id,
                        message.ts);
                }
            }
        }
    }

    this.chatServerReceived = (msg) => {
        // receive the confirmation, centang 1
        let ts = msg.ts;
        let id = msg.id;
        if (id === undefined) {
            return;
        }
        if (id.includes("proctor")) {
            // Debug("ignore include proctor");
            return;
        }
        let localStorageId = `${_this.context.profile.id}-${id}`
        let pm = _this.privateMessage.updateState(localStorageId, ts, id, true, false);

        _this.setState({
            privateMessage: pm
        })
    }

    this.chatReceiverReceived = (msg) => {
        let ts = msg.ts;
        let id = msg.id;

        let localStorageId = `${_this.context.profile.id}-${id}`

        if (_this.chatWs) {
            _this.chatWs.sendChatConfirmed(
                _this.context.profile.id,
                ts);
        }

        if (id.includes("proctor")) {
            return;
        }

        let pm = _this.privateMessage.updateState(
            localStorageId, ts, id, true, true);

        _this.setState({
            privateMessage: pm
        })
    }

    this.chatReceiveAllPrivateChat = (msg) => {
        const messages = msg.msg
        let pm = [];
        for (let m of messages) {
            const text = m.text
            const ts = m.ts
            const from_id = m.from_id
            pm = _this.privateMessage.merge(
                `${_this.context.profile.id}-${msg.from}`,
                ts,
                from_id,
                text,
                true
            )
        }
        if (pm.length > 0) {
            _this.setState({
                privateMessage: pm
            })
        }
    }

    this.broadcastDelete = (msg) => {
        let bm = _this.state.broadcastMessage;
        let nbm = [];
        for (let i in bm) {
            if (bm[i].from_id !== msg.from_id || bm[i].text !== msg.text) {
                nbm.push(bm[i]);
            }

        }
        _this.setState({ broadcastMessage: nbm })
    }

    this.broadcastHandler = (msg) => {
        let bm = _this.state.broadcastMessage;
        let x = msg["message"];
        for (let i = x.length - 1; i >= 0; i--) {
            if (x[i].ts > _this.broadcastMessageLastTs) {
                bm.push(x[i]);
                _this.broadcastMessageLastTs = x[i].ts;
            }
        }
        _this.setState({ broadcastMessage: bm });
    }

    this.chatWsHandler = (msg) => {
        // on message received
        msg = JSON.parse(msg);
        if (msg.type === "chat-message") {
            this.chatMessageHandler(msg);
        } else if (msg.type === "chat-server-recvd") {
            this.chatServerReceived(msg);
        } else if (msg.type === "chat-receiver-recvd") {
            this.chatReceiverReceived(msg);
        } else if (msg.type === "send-all-private-chat") {
            this.chatReceiveAllPrivateChat(msg)
        }
        ////// broadcast handler
        else if (msg.type === "broadcast-update") {
            _this.chatWs.sendGetBroadcastUpdate(_this.context.profile.id, _this.broadcastMessageLastTs);
        } else if (msg.type === "broadcast-delete") {
            this.broadcastDelete(msg);
        } else if (msg.type === "broadcast") {
            this.broadcastHandler(msg);
        }
    }

    this.chatWsRoutine = () => {
        if (_this.chatWs === null) {
            _this.chatWs = new ChatWs((msg) => {
                this.chatWsHandler(msg)
            }, (error) => {
                if (_this.chatWs) {
                    _this.chatWs.close();
                }
                _this.chatWs = null;
                _this.chatWsRegistered = false;
            })
            _this.chatWsAge = Date.now();
        } else if (_this.chatWsRegistered !== true || Date.now() - _this.chatWsLastRegisteredTs > CHATWS_RECALL) {
            let listOfParticipants = [];
            const par = _this.pm.getParticipants()
            for (let p in par) {
                listOfParticipants.push(par[p].id)
            }
            let reg = _this.chatWs.registerProctorer(_this.context.profile.id, listOfParticipants);

            _this.chatWsRegistered = reg;

            if (reg) {
                _this.chatWsLastRegisteredTs = Date.now();
                _this.chatWs.sendGetBroadcastUpdate(_this.context.profile.id, _this.broadcastMessageLastTs)
            }

            // send the message 
            for (let p in listOfParticipants) {
                _this.privateMessage.sendUnsentMessage(
                    _this.context.profile.id,
                    listOfParticipants[p],
                    _this.chatWs,
                    false
                );
            }
        }

        // disconnect chatws on purpose to take the benefit of autoscale
        if (_this.chatWs !== null && Date.now() - _this.chatWsAge >
            CHATWS_RECONNECT_PERIODE
        ) {
            Debug("close chatws, because reconnect");
            _this.chatWs.sendRefreshConnection(_this.context.profile.id);
            _this.chatWsAge -= 10000;
        }
    }

    this.chatWsOnMessageReceived = (msg) => {
        Debug("[chatws] msg=", msg)
    }

    this.chatWsOnError = (err) => {
        Debug("[chatws][error] err=", err);
        //handle on close and on error
        if (err !== null) {
            Debug("[chatws] close connection with error=", err);
        }
        delete _this.chatWs;
        _this.chatWs = null;
    }
}