import { ErrorAlert, SuccessAlert } from "../../components/Alert";
import CommonWebsocket from "../../libs/CommonWebsocket/CommonWebsocket";
import { Debug, Info } from "../../libs/logger";
import ProktorAI from "../../libs/ProktorAI";
import { __PrivateCall__ } from "./PrivateCall";

const COMMONWS_RECONNECT_PERIODE = 60000 * 10; // in ms, 60000 = 1m

export const __CommonWs__ = function (_this) {
    /** CommonWS */
    /*
    /** */
    _this.commonWs = null;
    _this.commonWsAge = Date.now();
    _this.updateTsRoutineLastTs = 0;
    _this.stateEmotion = {};
    _this.commonWsLastReadyTs = 0;
    _this.commonWsReinitialized = false;
    _this._privatecall = new __PrivateCall__(_this);

    this.commonWsProctorOnError = (_this, err) => {
        //handle on close and on error
        if (err !== null) {
            Debug("close connection with error=", err);
        }
        delete _this.commonWs;
        _this.commonWs = null;
    }

    this.commonWsProctorOnMessageReceived = (_this, msg) => {
        msg = JSON.parse(msg);
        Debug('[commonws]', msg);
        // webrtc
        // if (msg.type === 'webrtc-failed') {
        //     let participantId = msg.participantId;
        //     let mediaStatus = msg.mediaStatus;
        //     Debug("webrtc failed to start, id=", participantId, ", do nothing");
        //     _this.pm.webRTCAction(participantId, false, "liveVideo", mediaStatus);
        // } else if (msg.type === 'webrtc-started') {
        //     let participantId = msg.participantId;
        //     Debug("webrtc is started, do webrtc, id=", participantId);
        //     _this.pm.webRTCAction(participantId, true, "liveVideo");
        // }

        if (msg.type === 'webrtc-failed') {
            let participantId = msg.participantId;
            let mediaStatus = msg.mediaStatus;
            Debug("webrtc failed to start, id=", participantId, ", do nothing");
            // _this.pm.webRTCAction(participantId, false, "liveVideo", mediaStatus);
        } else if (msg.type === 'webrtc-started') {
            const { participantId, calltype } = msg;
            Debug("webrtc is started, do webrtc, id=", participantId);
            // _this.pm.webRTCAction(participantId, true, "liveVideo");
            _this.pm.webRTCGeneralAction(calltype, participantId, true)
        }

        // webrtc-private
        else if (msg.type === 'webrtc-private-failed') {

        } else if (msg.type === 'webrtc-private-started') {
            _this._privatecall.callParticipant(msg.proctorId, msg.participantId, msg.videoRouter);
        }


        // webrtc-mobile-pairing
        else if (msg.type === 'webrtc-mobile-pairing-failed') {

        } else if (msg.type === 'webrtc-mobile-pairing-started') {
            let participantId = msg.participantId;
            _this.pm.webRTCMobilePairingAction(participantId, true);
        }


        // webrtc-general
        else if (msg.type === 'webrtc-general-failed') {

        } else if (msg.type === 'webrtc-general-started') {
            const { participantId, calltype } = msg;
            _this.pm.webRTCGeneralAction(calltype, participantId, true)
        }



        else if (msg.type === "broadcast-to-participant") {
            // broadcast-to-participant will be handled by commonws not chatws
            _this.setState({ broadcastToParticipantMessage: msg });
        } else if (msg.type === "aiwarning") {
            let from = msg.from;
            let warningtype = msg.warningtype;
            let sAI = _this.state.stateAI;
            let eAI = _this.stateEmotion;
            if (msg.warningdata !== null) {
                let wa = ProktorAI.warningAnalytic(msg.warningdata,
                    eAI[from] === undefined ? { calm: 0, anxiety: 0, pasrah: 0 } :
                        eAI[from].emotion);
                sAI[from] = {
                    ts: Date.now(),
                    wt: warningtype,
                }

                eAI[from] = wa;
                _this.setState({
                    stateAI: sAI,
                    // stateEmotion: eAI
                })
                _this.stateEmotion = eAI;
            } else {
                Debug("warning data is null, perhaps it's sound handler");
                _this.pm.setSoundDetectedTs(from, Date.now());
                _this.setState(prevState => ({
                    soundDetectedTs: { ...prevState.soundDetectedTs, [from]: Date.now() }
                }))
            }
        } else if (msg.type === "update-ts-confirmed") {
            _this.updateTsRoutineLastTs = Date.now();
        } else if (msg.type === "update-validation-confirmed") {
            let status = msg.status
            if (status !== "success") {
                ErrorAlert(`${_this.state.participantId} - ${_this.state.participantProfile.name} gagal, ulangi kembali,`);

            } else {
                SuccessAlert(`${_this.state.participantId} - ${_this.state.participantProfile.name} telah di set ${msg.validation_status}`);
                _this._component.showPopupDetailParticipant(_this.state.participantId, _this.state.participantUUId, _this.state.temporaryVideoElId);
            }
        }
    }

    this.onValidProfile = () => {
        if (_this.commonWs === null) {
            _this.pm.setCommonWs(null);
            _this.commonWsReinitialized = true;
            _this.commonWs = new CommonWebsocket(_this, null, this.commonWsProctorOnMessageReceived, this.commonWsProctorOnError);
            _this.commonWsAge = Date.now();
        }

        if (_this.state.commonWsRegistered === false || _this.commonWsReinitialized === true) {
            let listOfParticipants = [];
            const par = _this.pm.getParticipants()
            for (let p in par) {
                listOfParticipants.push(par[p].id)
            }

            let reg = _this.commonWs.registerProctorer(_this.context.profile.id, listOfParticipants);
            _this.commonWsReinitialized = !reg;

            if (reg === true) {
                // _this.commonWs.sendGetBroadcastUpdate(_this.context.profile.id, _this.broadcastMessageLastTs)

                _this.setState({
                    commonWsRegistered: reg
                });
                setTimeout(() => {
                    _this.pm.setCommonWs(_this.commonWs);
                    _this.setState({ participantManagerCommonWs: true });
                }, 1000);
            }
        }
    }

    this.commonWsRoutine = () => {
        if (_this.commonWs === null) {
            if (Date.now() - _this.commonWsLastReadyTs > 10000) {
                _this.setState({
                    commonWsRegistered: false
                });
            }
        } else if (_this.commonWs.ready() === true) {
            _this.commonWsLastReadyTs = Date.now();
        } else if (Date.now() - _this.commonWsLastReadyTs > 10000) {
            _this.setState({
                commonWsRegistered: false
            });
        }

        if (_this.state.participantManagerStatus === false ||
            _this.state.fetchParticipantsStatus === false) {
            return;
        }
        if (_this.context.profile !== undefined) {
            this.onValidProfile();
        }

        // disconnect commonws on purpose to take the benefit of autoscale
        if (_this.commonWs !== null && Date.now() - _this.commonWsAge >
            COMMONWS_RECONNECT_PERIODE
        ) {
            Debug("close commonws, because reconnect");
            _this.commonWs.sendRefreshConnection(_this.context.profile.id);
        }
    }
}