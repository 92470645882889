import React from "react";
import classes from "./LoginEMSModal.module.scss";
import showpass from "../assets/imgs/showpassword.png";
import hidepass from "../assets/imgs/hidepassword.png";
import { ErrorAlert } from "./Alert";

class LoginEMSModal extends React.Component {
    constructor(props) {
        super(props);

        this.emailRef = React.createRef();
        this.fullnameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.confirmPasswordRef = React.createRef();

        this.state = {
            showPassword: false,
            showSignUp: false,
        }
    }

    renderSignUp() {
        return <div className={classes.container}>
            <div className={classes.container_title2}>
                <span onClick={() => {
                    this.setState({ showSignUp: false })
                }}>{"<"} Back</span>
                <div>Sign Up</div>
            </div>
            <div className={classes.container_login}>
                <div className={classes.split}>
                    <div>
                        <div className={classes.container_login_c}>
                            <div className={classes.title}>
                                <span>Email</span>
                                {/* <a href="#">Sign Up</a> */}
                            </div>
                            <input ref={this.emailRef} type="text"
                                onKeyDown={(k) => {
                                    if (k.key === "Enter") {
                                        this.props.onLogin(this.emailRef.current.value,
                                            this.passwordRef.current.value)
                                    }
                                }}
                            />
                        </div>
                        <div className={classes.container_login_c}>
                            <div className={classes.title}>
                                <span>Your Full Name</span>
                                {/* <a href="#">Sign Up</a> */}
                            </div>
                            <input ref={this.fullnameRef} type="text"
                            />
                        </div>
                        <div className={classes.container_login_c}>
                            <div className={classes.title}>
                                <span>Password</span>
                                <img
                                    alt="pass"
                                    src={this.state.showPassword === false ? showpass : hidepass}
                                    title={this.state.showPassword === false ? "tunjukkan password" : "sembunyikan password"}
                                    height="25px"
                                    onClick={() => {
                                        this.setState({ showPassword: !this.state.showPassword })
                                    }}
                                />
                            </div>
                            <input ref={this.passwordRef}
                                type={this.state.showPassword === true ? "text" : "password"}
                            />
                        </div>
                        <div className={classes.container_login_c}>
                            <div className={classes.title}>
                                <span>Confirm Password</span>
                                <img
                                    alt="pass"
                                    src={this.state.showPassword === false ? showpass : hidepass}
                                    title={this.state.showPassword === false ? "tunjukkan password" : "sembunyikan password"}
                                    height="25px"
                                    onClick={() => {
                                        this.setState({ showPassword: !this.state.showPassword })
                                    }}
                                />
                            </div>
                            <input ref={this.confirmPasswordRef}
                                type={this.state.showPassword === true ? "text" : "password"}
                            />
                        </div>
                    </div>
                    <div>
                        VIDEO
                    </div>
                </div>
                <div className={classes.container_login_c}>
                    <div className={[classes.btnOk, classes.btnLogin].join(" ")}
                        onClick={() => {
                            const email = this.emailRef.current.value;
                            const password = this.passwordRef.current.value;
                            const confirmPassword = this.confirmPasswordRef.current.value;
                            const fullname = this.fullnameRef.current.value;
                            if (email === "" || password === "" || fullname === "") {
                                ErrorAlert(`Email, Password and Fullname must be filled`);
                                return;
                            }
                            if (password !== confirmPassword) {
                                ErrorAlert(`Password did not match`);
                                return;
                            }
                            this.props.onSignup(
                                this.emailRef.current.value,
                                this.passwordRef.current.value,
                                this.fullnameRef.current.value)
                        }}
                    >
                        Register
                    </div>
                </div>

            </div>
        </div>
    }

    render() {
        if (this.state.showSignUp) {
            return this.renderSignUp();
        }
        return <div className={classes.container}>
            <div className={classes.container_title}>
                Login
            </div>
            <div className={classes.container_login}>
                <div className={classes.container_login_c}>
                    <div className={classes.title}>
                        <span>Email</span>
                        {/* <a href="#">Sign Up</a> */}
                    </div>
                    <input ref={this.emailRef} type="text"
                        onKeyDown={(k) => {
                            if (k.key === "Enter") {
                                this.props.onLogin(this.emailRef.current.value,
                                    this.passwordRef.current.value)
                            }
                        }}
                    />
                </div>
                <div className={classes.container_login_c}>
                    <div className={classes.title}>
                        <span>Password</span>
                        <img
                            alt="pass"
                            src={this.state.showPassword === false ? showpass : hidepass}
                            title={this.state.showPassword === false ? "tunjukkan password" : "sembunyikan password"}
                            height="25px"
                            onClick={() => {
                                this.setState({ showPassword: !this.state.showPassword })
                            }}
                        />
                    </div>
                    <input ref={this.passwordRef}
                        type={this.state.showPassword === true ? "text" : "password"}
                        onKeyDown={(k) => {
                            if (k.key === "Enter") {
                                this.props.onLogin(this.emailRef.current.value,
                                    this.passwordRef.current.value)
                            }
                        }}
                    />
                </div>
                <div className={classes.container_login_signup}>
                    <span>Forgot Password ?</span>
                    <span onClick={() => {
                        this.setState({ showSignUp: true });
                    }}>
                        SignUp
                    </span>
                </div>
                <div className={classes.container_login_c}>
                    <div className={[classes.btnOk, classes.btnLogin].join(" ")}
                        onClick={() => {
                            this.props.onLogin(this.emailRef.current.value,
                                this.passwordRef.current.value)
                        }}
                    >
                        Login
                    </div>
                </div>
            </div>
            <div className={classes.container_sep}>
                <div>
                </div>
                <span>atau</span>
                <div></div>
            </div>
            {/* <div className={classes.container_oauth}>
                <div className={classes.btnHelp}
                    onClick={() => this.props.onLoginSSO()}
                >
                    Login dengan SSO Telkom University
                </div>
            </div> */}

            <div>
                {this.props.onFormGoogleAuth()}
                {/* {this.props.onFormFacebookAuth()} */}
            </div>

            <div className={classes.container_oauth}>
                {/* <div className={classes.btnHelp}
                    onClick={() => this.props.onLoginOAUTH()}
                >
                    Login dengan Google
                </div> */}
            </div>

            <div className={classes.container_sep}>
                <div>
                </div>
                {/* <span>atau</span> */}
                <div></div>
            </div>
            <div className={classes.container_close}>
                <div className={classes.btnReload}
                    onClick={() => {
                        this.props.onClose()
                    }}
                >
                    Tutup
                </div>
            </div>
        </div>
    }
}

export default LoginEMSModal;