import React from "react";
import classes from "./ParticipantVideo.module.scss";
import { Lang } from "../../libs/Language";
import { LANG, validationValueColor } from "../../libs/utils";
import { getPhotoFromLocal, TrimText } from "../../libs/proctor_utils.js";
import PropTypes from 'prop-types';

import newmessage from "../../assets/imgs/newmessage.png";
import panic from "../../assets/imgs/anxiety.png";
import anxiety from "../../assets/imgs/pasrah.png";
import happy from "../../assets/imgs/happy.png";
import livevideo from "../../assets/imgs/livevideo.png";
import webcam from "../../assets/imgs/webcam.png";
import screencap from "../../assets/imgs/screencap.png";
import ConnectionBar from "../ConnectionBar.js";
import audioon from "../../assets/imgs/audioon.png";
import settings from "../../assets/imgs/settings.png";
import call from "../../assets/imgs/call.png";
import restart from "../../assets/imgs/restart.png";
import webcam2 from "../../assets/imgs/webcam2.png";
import update from "../../assets/imgs/update.png";
import logout from "../../assets/imgs/logout_force.png";
import hangup from "../../assets/imgs/endcall.png";
import audio_detected from "../../assets/imgs/audio_detected.png";

export const initHelpers = (_this) => {
    _this.videoRef = React.createRef();
    _this.imgFaceRef = React.createRef();
    _this.imgScreenRef = React.createRef();
}

export const renderPhotoId = (_this, width) => {
    return _this.props.showID && <div className={classes.img}
        title={_this.props.participantId + "-" + _this.props.participantName}

        style={{
            width: width + "px",
            height: (width * 0.8) + "px"
        }}
        onClick={() => {
            _this.props.onClick();
        }}
    >
        <img
            alt="id"
            src={getPhotoFromLocal(_this.props.photoUrl)}
            style={{
                maxWidth: width + "px",
                maxHeight: (width * 0.8) + "px"
            }}
        />
    </div>
}

export const renderLiveVideoFace = (_this, width) => {
    return <video
        title={_this.props.participantId + "-" + _this.props.participantName}
        muted={true}
        autoPlay={true}
        ref={_this.videoRef}
        id={`liveVideo_${_this.props.proctorId}`}
        style={{
            maxWidth: width + "px",
            maxHeight: (width * 0.8) + "px",
            display: "none"
        }}
        onClick={() => {
            _this.props.onClick();
        }}
    >
    </video>
}

export const renderLiveImageFace = (_this, width) => {
    return <div
        title={_this.props.participantId + "-" + _this.props.participantName}
        className={classes.img}
        id={"img_" + _this.props.proctorId}
        style={{
            width: width + "px",
            height: (width * 0.8) + "px"
        }}
        onClick={() => {
            _this.props.onClick();
        }}
    >
        <img
            alt="head"
            className={classes.transition}
            ref={_this.imgFaceRef}
            src={_this.state.imgUrl}
            style={{
                maxWidth: width + "px",
                maxHeight: (width * 0.8) + "px"
            }}
            onError={() => {
                setTimeout(() => {
                    _this.imgFaceRef.current.src = _this.state.imgUrl;
                }, 1000);
            }}
        />
    </div>
}

export const renderLiveImageScreen = (_this, width) => {
    return _this.props.showScreen && <div className={classes.img}
        style={{
            width: width + "px",
            height: (width * 0.8) + "px"
        }}
        onClick={() => {
            _this.props.onClick();
        }}
        id={`imgScreenCapture_${_this.props.proctorId}`}
    >
        <img
            alt="id"
            src={_this.state.scrUrl}
            ref={_this.imgScreenRef}
            id={`imgScreenCapture_${_this.props.proctorId}`}
            style={{
                maxWidth: width + "px",
                maxHeight: (width * 0.8) + "px"
            }}
            onError={() => {
                _this.imgScreenRef.current.src = _this.state.scrUrl;
            }}
        />
    </div>
}

export const renderLiveVideoScreen = (_this, width) => {
    return <video
        className={classes.video}
        title={_this.props.participantId + "-" + _this.props.participantName}
        muted={true}
        autoPlay={true}
        ref={_this.videoScreenRef}
        id={`screenCapture_${_this.props.proctorId}`}
        style={{
            maxWidth: width + "px",
            maxHeight: (width * 0.8) + "px",
            // visibility: _this.props.showScreen ? "visible" : "hidden"
            // display: "none"
            // border:"2px solid green"
        }}
        onClick={() => {
            _this.props.onClick();
        }}
    >
    </video>
}

export const renderOnlineOffline = (_this, width) => {
    return <div
        id={'status_' + _this.props.proctorId}
        style={{
            width: width + "px",
            height: (width * 0.8) + "px",
            border: "0px solid green"
        }}
        className={classes.status}
        onClick={() => {
            _this.props.onClick()
        }}
    >
        <div>offline</div>
    </div>
}

export const renderLiveMobilePairing = (_this, width) => {
    return <div className={classes.mobilepairing}>
        <div>
            <video
                className={classes.video}
                title={_this.props.participantId + "-mobilepairing-" + _this.props.participantName}
                muted={true}
                ref={_this.mobilePairingRef}
                id={`mobilePairing_${_this.props.proctorId}`}
                controls={false}
                autoPlay={true}
                style={{
                    maxWidth: width + "px",
                    maxHeight: (width * 0.8) + "px"
                }}
                onClick={() => {
                    _this.props.onClick();
                }}
            >
            </video>
        </div>
        {/* <div> */}
        {/* <div className={classes.img}
        style={{
          // width: width + "px",
          // height: (width * 0.8) + "px"
        }}
        onClick={() => {
          _this.props.onClick();
        }}
      >
        <img
          alt="id"
          src={_this.state.scrUrl}
          ref={_this.imgMobilePairingRef}
          style={{
            // maxWidth: width + "px",
            // maxHeight: (width * 0.8) + "px"
          }}
          onError={() => {
            _this.imgScreenRef.current.src = _this.state.scrUrl;
          }}
        />
      </div> */}
        {/* </div> */}
    </div>
}

export const renderMultiInformation = (_this) => {
    let vel = null;
    if (_this.props.dynvalidation.value !== "") {
        let w = "7px";
        // if (_this.props.dynvalidation.confirmed !== "1") {
        //   w = "17px";
        // } else {
        //   w = "7px";
        // }
        vel = <div
            className={[
                classes.container_v_validation
            ].join(" ")}
            style={{
                backgroundColor: validationValueColor(
                    _this.props.dynvalidation.green,
                    _this.props.dynvalidation.yellow,
                    _this.props.dynvalidation.orange,
                    _this.props.dynvalidation.pink,
                    _this.props.dynvalidation.red,
                    _this.props.dynvalidation.value,
                ),
                width: w,
                height: w
            }}
            title={`${Lang('tingkat kemiripan', LANG(), { en: 'similarity level' })} ${((1 - _this.props.dynvalidation.value) * 100).toFixed(2)}%`}
        >
            {/* need confirmation */}
        </div>
    }

    return <>
        {vel}
        {_this.props.liveVideoAvailable && <img
            alt="livevideo"
            title="live video is on"
            className={[classes.container_v_abs, classes.container_v_abs_livevideo, classes.FadeInPage1s].join(" ")}
            src={livevideo}
        />
        }
        {_this.props.soundDetected && <div className={classes.container_v_hear}>
            <img alt="audio" src={audio_detected} width={"20px"} />
        </div>
        }
        {
            (Date.now() - _this.props.webcamScreenAvailableTs < 60000 && _this.props.webcamAvailable) && <img
                alt="livevideo"
                title="webcam is on"
                className={[classes.container_v_abs, classes.container_v_abs_webcam, classes.FadeInPage1s].join(" ")}
                src={webcam}
            />
        }
        {
            (Date.now() - _this.props.webcamScreenAvailableTs < 60000 && _this.props.screencapAvailable) && <img
                alt="livevideo"
                title="screencapture is on"
                className={[classes.container_v_abs, classes.container_v_abs_screencap, classes.FadeInPage1s].join(" ")}
                src={screencap}
            />
        }
        {
            (Date.now() - _this.props.webcamScreenAvailableTs < 60000 && _this.props.responseTime) && <div
                title={`connection status: ${(_this.props.responseTime / 1000).toFixed(1)}`}
                className={[classes.container_v_abs, classes.container_v_abs_avg].join(" ")}>
                <ConnectionBar
                    avgResponseTime={_this.props.responseTime}
                    type="small" />
            </div>
        }
        {
            _this.props.liveVideoAvailable && <img
                alt="audio"
                className={[classes.container_v_abs, classes.container_v_abs_audio, classes.FadeInPage1s].join(" ")}
                title="audio on"
                src={audioon}
                style={{
                    width: _this.props.unmutedAudioElementId === _this.props.proctorId ? "24px" : "12px",
                    top: _this.props.unmutedAudioElementId === _this.props.proctorId ? "-15px" : null
                }}
                onClick={() => {
                    if (_this.props.onAudioOnOff) {
                        _this.props.onAudioOnOff()
                    }
                }}
            />
        }
        {
            <div
                className={[classes.container_v_abs, classes.container_v_abs_info, classes.FadeInPage1s].join(" ")}
            >
                <div>
                    <span>image akan di update dalam 3 detik</span>
                </div>
            </div>
        }
    </>
}


export const renderEmotion = (_this, SW, width) => {
    const calculateEmotionImageHeight = () => {
        return 17 + Math.ceil(4 * (_this.props.scale > 5 ? 5 : _this.props.scale))
    }

    return SW !== 0 && <div
        className={classes.stats}
        style={{
            width: `${SW}px`,
            height: `${width * 0.8}px`
        }}
        onClick={() => {
            _this.props.onClick();
        }}
    >{
            (_this.props.emotion && _this.props.emotion.calm !== -1) && <div>
                <div title="tenang">
                    <img alt="happy" src={happy} height={`${calculateEmotionImageHeight()}px`} />
                    <span>
                        {_this.props.emotion ? _this.props.emotion.calm : "00"}%
                    </span>
                </div>
                <div title="gelisah">
                    <img alt="anxiety" src={anxiety} height={`${calculateEmotionImageHeight()}px`} />
                    <span>
                        {_this.props.emotion ? _this.props.emotion.anxiety : "00"}%
                    </span>
                </div>
                <div title="panik">
                    <img alt="panik" src={panic} height={`${calculateEmotionImageHeight()}px`} />
                    <span>
                        {_this.props.emotion ? _this.props.emotion.pasrah : "00"}%
                    </span>
                </div>
            </div>
        }
    </div>
}

export const renderParticipantWarningInfo = (_this, TW, colorValidation, emotionLength, warningMessage) => {
    return <div className={[
        classes.container_t,
        _this.props.aiwarning !== -1 ?
            classes.warning : null].join(" ")
    }
        onClick={() => {
            _this.props.onClick();
        }}
    >
        <div>
            <span
                className={classes.container_t_i}
                style={colorValidation}
                title={_this.props.participantId + "-" + _this.props.participantName}
            >
                {TrimText(_this.props.participantId, TW === 1 ? _this.props.scale * 16 + emotionLength : _this.props.scale * (TW * 16) + emotionLength)}
            </span>
            <span
                className={classes.container_t_n}
                title={_this.props.participantId + "-" + _this.props.participantName}
            >
                {TrimText(_this.props.participantName, TW === 1 ? _this.props.scale * 16 + emotionLength : _this.props.scale * (TW * 16) + emotionLength)}
            </span>
        </div>
        {
            _this.props.aiwarning === -1 ?
                null :
                <span className={classes.container_t_w}
                    title={warningMessage}
                >
                    <span>!</span>
                    {TrimText(warningMessage, TW === 1 ? _this.props.scale * 16 + emotionLength : _this.props.scale * (TW * 16) + emotionLength)}
                </span>
        }
    </div>
}

export const renderNewMessage = (_this) => {
    return _this.props.newMessage && _this.props.newMessage === true &&
        <div className={[classes.container_v_msg, classes.FadeInPage1s].join(" ")}>
            <img
                alt="chat"
                src={newmessage}
                onClick={() => {
                    _this.props.onClick()
                }}
            />
        </div>
}

export const renderConfiguration = (_this) => {
    const renderItem = (src, txt, onClick) => {
        return <div>
            <img
                alt={txt}
                width={"20px"}
                height={"20px"}

                src={src}
                title={txt}
                onClick={onClick}
            />
            <span title={txt}
                onClick={onClick}
            >{txt}</span>
        </div>
    }
    return <div className={[
        classes.configuration, classes.ascroll].join(" ")}
        style={{
            visibility: _this.state.showConfiguration ? "visible" : "hidden",
            opacity: _this.state.showConfiguration ? "1" : "0"
        }}
    >
        <img alt="settings" src={settings} width={"25px"}
            onClick={() => {
                _this.setState(prevState => ({ showConfigurationModal: !prevState.showConfigurationModal }))
            }}
        />
        <div className={[classes.configuration_modal, classes.ascroll].join(" ")}
            style={{
                visibility: _this.state.showConfigurationModal ? "visible" : "hidden",
                opacity: _this.state.showConfigurationModal ? "1" : "0"
            }}
        >
            {renderItem(update, "update images", () => {
                _this.props.onUpdateParticipant()
            })}
            {_this.props.isHangup ?
                renderItem(call, "call", () => {
                    _this.props.onCallParticipant()
                })
                :
                renderItem(hangup, "hangup", () => {
                    _this.props.onCallParticipant()
                })
            }
            {_this.props.pm.getDoNotRun("liveVideo", _this.props.participantId) === true ?
                renderItem(restart, "connect face video", () => {
                    // _this.props.onLiveVideoReconnect();
                    _this.props.pm.switchDoNotRun("liveVideo", _this.props.participantId);

                }) :
                renderItem(restart, "disconnect face video", () => {
                    // _this.props.onLiveVideoReconnect();
                    _this.props.pm.switchDoNotRun("liveVideo", _this.props.participantId);
                })
            }
            {
                _this.props.pm.getDoNotRun("screenCapture", _this.props.participantId) === true ?
                    renderItem(restart, "connect screen video", () => {
                        _this.props.pm.switchDoNotRun("screenCapture", _this.props.participantId);
                    })
                    :
                    renderItem(restart, "disconnect screen video", () => {
                        _this.props.pm.switchDoNotRun("screenCapture", _this.props.participantId);
                    })
            }
            {
                _this.props.pm.getDoNotRun("mobilePairing", _this.props.participantId) === true ?
                    renderItem(restart, "connect mobile pairing video", () => {
                        _this.props.pm.switchDoNotRun("mobilePairing", _this.props.participantId);
                    })
                    :
                    renderItem(restart, "disconnect mobile pairing video", () => {
                        _this.props.pm.switchDoNotRun("mobilePairing", _this.props.participantId);
                    })
            }
            {/* {renderItem(webcam2, "bring image webcam", () => {
                _this.props.onImageShowHide();
            })} */}
            {renderItem(logout, "logout", () => {
                _this.props.onLogoutParticipant();
            })}
        </div>
    </div>
}

export const getWarningMessage = (_this) => {
    let warningMessage = "unknown";
    if (_this.props.aiwarning === 0) {
        warningMessage = Lang("Wajah tidak terdeteksi.", LANG(), { en: `Face not detected.` })
    } else if (_this.props.aiwarning === 1) {
        warningMessage = Lang("Terdapat lebih dari 1 wajah.", LANG(), { en: `There are more than 1 face.` })
    } else if (_this.props.aiwarning === 2) {
        warningMessage = Lang("Wajah tidak sesuai dengan kartu identitas.", LANG(), { en: `The face does not match the id card.` })
    } else if (_this.props.aiwarning === 3) {
        warningMessage = Lang("Ada tab atau app lain dibuka.", LANG(), { en: `There is another tab or app open.` })
    } else if (_this.props.aiwarning === 4) {
        warningMessage = Lang("Melakukan Print Screen atau Screenshot.", LANG(), { en: `Performing Print Screen or Screenshot.` })
    } else if (_this.props.aiwarning === 5) {
        warningMessage = Lang("Terdeteksi Melakukan Copy/Paste.", LANG(), { en: `Copy/Paste Detected.` })
    }
    return warningMessage;
}

export const ParticipantVideoPropTypes = {
    aiwarning: PropTypes.number.isRequired,
    newMessage: PropTypes.bool.isRequired,
    dynvalidation: PropTypes.object.isRequired,
    photoUrl: PropTypes.string.isRequired,
    webcamScreenAvailableTs: PropTypes.number,
    webcamAvailable: PropTypes.bool,
    screencapAvailable: PropTypes.bool,
    responseTime: PropTypes.number,
    liveVideoAvailable: PropTypes.bool.isRequired,
    unmutedAudioElementId: PropTypes.string.isRequired,
    onAudioOnOff: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onCallParticipant: PropTypes.func,
    onUpdateParticipant: PropTypes.func,
    updateParticipantTs: PropTypes.number,
    onLiveVideoReconnect: PropTypes.func,
    onImageShowHide: PropTypes.func,
    onLogoutParticipant: PropTypes.func,
    isHangup: PropTypes.bool,
    soundDetected: PropTypes.bool.isRequired
};