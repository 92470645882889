import { useState, useEffect } from "react";
import {
  getExamTokenName,
  getIframeTokenName,
  getTokenName,
} from "./proctor_utils";
import { Lang } from "./Language";
import { Debug, Err, Info } from "./logger";
import { Image } from "image-js";
import classes from "./utils.module.scss";

export const MAX_DEVICE_ERROR_RETRY = 3;
// export const ALLOW_NO_DEVICE = false
export const ALLOW_NO_DEVICE = true;

export const MOBILE_PAIRING_MODE = true;

export const LANG = () => {
  return localStorage.getItem("lang") ? localStorage.getItem("lang") : "id";
};

/// response time
const responseTime = [];
export const addResponseTime = (rtime) => {
  responseTime.push(rtime);
  if (responseTime.length > 60) {
    responseTime.shift();
  }
};
export const avgResponseTime = () => {
  const totalResponseTime = responseTime.reduce((acc, time) => acc + time, 0);
  const avgResponseTime = totalResponseTime / responseTime.length;
  return avgResponseTime;
};
/// response time

const getStreamWithElectron = async () => {
  const sources = await window.electron.getScreenSources()
  Info(`Available screen sources: ${sources}`);

  // choose entire screen
  let id = 0;
  for (let i in sources) {
    if (sources[i].name === 'Entire Screen') {
      id = i;
      break;
    }
  }

  const sourceId = sources[id]?.id;
  if (sourceId) {
    // Meminta stream menggunakan ID sumber layar
    return await navigator.mediaDevices.getUserMedia(
      {
        video: {
          mandatory: {
            chromeMediaSource: 'desktop',
            chromeMediaSourceId: sourceId
          }
        }
      }
    )
  }
  return null;
}

const getDisplayMedia = async (screenDefaultConstraints) => {
  if (window.electron) {
    return getStreamWithElectron();
  }
  return navigator.mediaDevices.getDisplayMedia(screenDefaultConstraints)
}

export const initializeMedias = (
  videoResolution,
  captureFace = true,
  captureScreen = false,
  captureWebRtc = false,
  errorCaptureStatus = {
    face: true, // true means: initialized for the first time or it's error, so it's retried
    screen: true,
    webrtc: true,
  },
  captureFaceHandler = null,
  captureScreenHandler = null,
  captureWebRTCHandler = null,
  captureErrorHandler = null
) => {
  // Err("error capture status = ", errorCaptureStatus);
  let faceDefaultConstraints = {
    audio: true,
    video: {
      frameRate: { ideal: 10, max: 10, min: 10 },
      width: { ideal: 480, max: 480 },
      height: { ideal: 360, max: 360 },
    },
  };

  let a = videoResolution.split("@")[0];
  let b = videoResolution.split("@")[1];
  let width = a.split("x")[0];
  let height = a.split("x")[1];
  let fps = b.replace("fps", "");

  faceDefaultConstraints.video = {
    frameRate: { ideal: fps, max: 30 },
    width: { ideal: width, max: width * 2 },
    height: { ideal: height, max: height * 2 },
  };

  const screenDefaultConstraints = {
    // this configuration is used for the webrtc live
    audio: false,
    video: {
      facingMode: "user",
      // 50KBps => 0.4mbps
      frameRate: { ideal: 10, max: 30 },
      width: { ideal: 1080, max: 1920 },
      height: { ideal: 608, max: 1080 },
    },
  };

  Debug(`Capture Face = `, captureFace, errorCaptureStatus);
  if (captureFace === true && errorCaptureStatus.face === true) {
    navigator.mediaDevices
      .getUserMedia(faceDefaultConstraints)
      .then((stream) => {
        if (captureFaceHandler !== null) {
          captureFaceHandler(stream);
        }
      })
      .catch((err) => {
        Err("error occured when trying to get an access to your camera", err);
        if (
          captureFaceHandler !== null &&
          typeof captureErrorHandler === "function"
        ) {
          captureErrorHandler("face", err);
        }
      });
  }

  if (captureScreen === true && errorCaptureStatus.screen === true) {
    // Meminta sumber layar dari main process
    // Pilih sumber layar pertama dan tampilkan pratinjau (untuk testing)
    getDisplayMedia(screenDefaultConstraints).then((stream) => {
      stream.onaddtrack = (t) => {
        Debug("on add track", t);
      };

      // stream.onremovetrack = (t) {
      // }

      stream.onactive = (a) => {
        Debug("on active", a);
      };

      stream.oninactive = (a) => {
        Debug("on inactive", a);
      };

      if (captureScreenHandler !== null) {
        captureScreenHandler(stream);
      }
    }).catch((err) => {
      Err("error occured when trying to get an access to your camera", err);
      captureErrorHandler("screen", err);
    });
  }

  if (captureWebRtc === true && errorCaptureStatus.webrtc === true) {
    // webrtc live video
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          facingMode: "user",
          frameRate: { ideal: 8, max: 8 },
          width: { ideal: 160, max: 160 },
          height: { ideal: 120, max: 120 },
        },
      })
      .then((stream) => {
        captureWebRTCHandler(stream);
      })
      .catch((err) => {
        Err("[error] get media", err);
        captureErrorHandler("webrtc", err);
      });
  }
};

export const toggleDevelopment = false;

export const convertTimestampToExportDate = (time) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

export const lowerCaseMode = (mode) => {
  switch (mode) {
    case "Automatic Number Plate Recognition":
      return "anpr";
    case "Automatic Something 1":
      return "anpr1";
    case "Automatic Something 2":
      return "anpr2";
    default:
      return mode.toLowerCase().replace(" ", "-");
  }
};

export const beautyMode = (mode) => {
  switch (mode) {
    case "object-detection":
      return "Object Detection";
    case "queue-detection":
      return "Queue Detection";
    case "face-recognition":
      return "Face Recognition";
    case "motion-detection":
      return "Motion Detection";
    case "Recording":
      return "Recording";
    case "heat-map":
      return "Heat Map";
    case "human-skeleton":
      return "Human Skeleton";
    case "fire-and-smoke-detection":
      return "Fire and Smoke Detection";
    case "anpr":
      return "Automatic Number Plate Recognition";
    default:
      return "Unknown-" + mode;
  }
};

export const convertTimestampToNumericDate = (time) => {
  let options = {
    // weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",

    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("id-ID", options).format(time);
};

export const convertTimestampToDate = (time) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",

    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
    // hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat(
    Lang("id-ID", LANG(), { en: "en-EN" }),
    options
  ).format(time);
};

export const convertTimestampToDayMonth = (time) => {
  let options = {
    // weekday: "long",
    // year: "numeric",
    month: "short",
    day: "numeric",

    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
    // hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("id-ID", options).format(time);
};

export const convertTimestampToDateShort = (time) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",

    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
    // hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("id-ID", options).format(time);
};

export const convertTimestampToTimeHourMinute = (time) => {
  let options = {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

export const convertTimestampToTime = (time) => {
  let options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

export const convertTimestampToSecond = (time) => {
  let options = {
    hour: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };
  let hour = new Intl.DateTimeFormat("en-US", options).format(time);

  options = {
    minute: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };
  let minute = new Intl.DateTimeFormat("en-US", options).format(time);

  options = {
    second: "numeric",
    hour12: false,
    fractionalSecondDigits: 3,
  };
  let second = new Intl.DateTimeFormat("en-US", options).format(time);

  return parseInt(hour) * 3600 + parseInt(minute) * 60 + parseFloat(second);
};

export const convertTimestampToTimeInt = (time) => {
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

export const convertDurationToBetterFormatFraction3 = (dur) => {
  let minute = Math.floor(dur / 60);
  let second = (dur % 60).toFixed(3);
  let str_minute = minute !== 0 ? minute + "m " : "";
  let str_second = second <= 0.0 ? "" : second + "d";
  let str =
    dur.toFixed(3) +
    "d (" +
    str_minute +
    (str_second !== "" ? " " + str_second : "") +
    ")";
  return str;
};

export const convertDurationToBetterFormatInt = (dur) => {
  dur = Math.floor(dur);
  let minute = Math.floor(dur / 60);
  let second = dur - minute * 60;
  let str_minute = minute !== 0 ? minute + "m " : "";
  let str_second = second <= 0.0 ? "" : second + "d";
  let str =
    dur.toFixed(0) +
    "d (" +
    str_minute +
    (str_second !== "" ? " " + str_second : "") +
    ")";
  return str;
};

export const convertDurationToBetterFormatInt2 = (dur) => {
  dur = Math.floor(dur);

  let hours = Math.floor(dur / 3600);
  let minute = Math.floor((dur % 3600) / 60);
  let second = dur % 60;

  // Pad the values with leading zeros if they are less than 10
  let str_hours = String(hours).padStart(2, "0");
  let str_minute = String(minute).padStart(2, "0");
  let str_second = String(second).padStart(2, "0");

  // Combine the values into HH:MM:SS format
  let str = `${str_minute}:${str_second}`;

  return str;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const modeToMpegTsPort = (
  mode,
  camera_id,
  url_substream_available = false
) => {
  let port;
  if (url_substream_available === false) {
    if (mode === "Watch") {
      mode = "WatchHD";
    }
  }
  switch (mode) {
    case "Watch":
      port = 26000 + camera_id;
      break;
    case "WatchHD":
      port = 6000 + camera_id;
      break;
    case "Face Recognition":
      port = 8000 + camera_id;
      break;
    case "Motion Detection":
      port = 10000 + camera_id;
      break;
    case "Queue Detection":
      port = 12000 + camera_id;
      break;
    case "Object Detection":
      port = 14000 + camera_id;
      break;
    case "Heat Map":
      port = 16000 + camera_id;
      break;
    case "Human Skeleton":
      port = 18000 + camera_id;
      break;
    case "Fire and Smoke Detection":
      port = 20000 + camera_id;
      break;
    case "Automatic Number Plate Recognition":
      port = 22000 + camera_id;
      break;
    default:
      port = 26000 + camera_id;
  }
  return port;
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const CameraModes = [
  "Inactive",
  "Watch",
  "Recording",
  "Face Recognition",
  "Motion Detection",
  "Queue Detection",
  "Object Detection",
  "Heat Map",
  "Human Skeleton",
  "Fire and Smoke Detection",
  "Automatic Number Plate Recognition",
];

export const fetchDataBlob = async (url, jsonData, blob, errorListener) => {
  const formData = new FormData();
  for (let key in jsonData) {
    formData.append(key, jsonData[key]);
  }
  formData.append("file", blob); // Menambahkan file atau Blob ke FormData

  const startTs = performance.now();
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: formData,
  };

  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        let error = response.status;
        if (data && data.message) {
          error = data.message;
        }
        return Promise.reject(error);
      }
      return data;
    })
    .catch((error) => {
      errorListener(error);
    })
    .finally(() => {
      const rTime = performance.now() - startTs;
      if (rTime) {
        addResponseTime(rTime);
        // Debug("average response time=", avgResponseTime());
      }
    });
};

export const fetchDataJSON = async (
  url,
  jsonData,
  errorListener,
  responseTime
) => {
  const startTs = performance.now();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: JSON.stringify(jsonData),
  };
  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        let error = response.status;
        if (data) {
          error = data.message;
        }
        return Promise.reject(error);
      } else {
        return data;
      }
    })
    .catch((error) => {
      errorListener(error);
    })
    .finally(() => {
      const rTime = performance.now() - startTs;
      if (rTime) {
        addResponseTime(rTime);
        // Debug("average response time=", avgResponseTime());
      }
    });
};

export const EncryptDataFromJson = (data) => {
  let CryptoJS = require("crypto-js");
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    localStorage.getItem(getTokenName())
  ).toString();
};

export const DecryptDataToJson = (ciphertext) => {
  let CryptoJS = require("crypto-js");
  let bytes = CryptoJS.AES.decrypt(
    ciphertext,
    localStorage.getItem(getTokenName())
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const formatRupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    maximumSignificantDigits: 10,
    style: "currency",
    currency: "IDR",
  }).format(number);
};

export const getBilling = (page, ratePerPage) => {
  return formatRupiah(page * ratePerPage);
};

export const sumPrice = (items, ratePerPage) => {
  return items.reduce((acc, curr) => acc + curr * ratePerPage, 0);
};

export const totalPage = (items) => {
  return items.reduce((acc, curr) => acc + curr, 0);
};

const ones = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];

const tens = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

const teens = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];

const convert_millions = (num) => {
  if (num >= 1000000) {
    return (
      convert_millions(Math.floor(num / 1000000)) +
      " million " +
      convert_thousands(num % 1000000)
    );
  } else {
    return convert_thousands(num);
  }
};
const convert_thousands = (num) => {
  if (num >= 1000) {
    return (
      convert_hundreds(Math.floor(num / 1000)) +
      " thousand " +
      convert_hundreds(num % 1000)
    );
  } else {
    return convert_hundreds(num);
  }
};
const convert_hundreds = (num) => {
  if (num > 99) {
    return ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100);
  } else {
    return convert_tens(num);
  }
};
const convert_tens = (num) => {
  if (num < 10) return ones[num];
  else if (num >= 10 && num < 20) return teens[num - 10];
  else {
    return tens[Math.floor(num / 10)] + " " + ones[num % 10];
  }
};

export const convertNumberToWords = (num) => {
  if (num === 0) return "zero";
  else return convert_millions(num);
};

//export const urlLocal = 'console.amanin.id';
// export const urlLocal = window.location.hostname === "localhost" ? 'proktor.amanin.id' : window.location.host;
export const TEST_URL1 = "console.amanin.id";
export const TEST_URL2 = "schoters.amanin.id";

export const urlLocal =
  window.location.hostname === "localhost"
    ? TEST_URL2
    : window.location.host;

export const formatTime = (times) => {
  const result = times < 10 && times !== "" ? `0${times}` : times;
  return result || "-";
};

export const convertEpochTime = (epochTime) => {
  const dateObj = new Date(epochTime * 1000); // Convert epoch time to milliseconds
  return dateObj;
};

export const convertStringIdToNumberId = (v) => {
  let i;
  let r = v;
  // Using for loop for (A-Z):
  for (i = 65; i <= 90; i++) {
    r = r.replaceAll(String.fromCharCode(i), "9");
  }

  // Using for loop for (a-z):
  for (i = 97; i <= 122; i++) {
    r = r.replaceAll(String.fromCharCode(i), "9");
  }
  return r;
};
export const validationValueColor2 = (dynvalidation) => {
  return validationValueColor(
    dynvalidation.green,
    dynvalidation.yellow,
    dynvalidation.orange,
    dynvalidation.pink,
    dynvalidation.red,
    dynvalidation.value
  );
};

export const validationValueColor = (
  green,
  yellow,
  orange,
  pink,
  red,
  value
) => {
  if (value < green) {
    return "green";
  } else if (value < yellow) {
    return "yellow";
  } else if (value < orange) {
    return "orange";
  } else if (value < pink) {
    return "pink";
  }
  return "red";
};

export const mobileMediaQueryHandleScreenChange = (_this, event) => {
  if (event.matches) {
    // mobile handler
    _this.setState({ desktopView: false });
  } else {
    // desktop handler
    _this.setState({ desktopView: true });
  }
};

export const mobileMediaQueryRemoveHandler = (
  mobileMediaQuery,
  handleScreenChange
) => {
  mobileMediaQuery?.removeEventListener("change", handleScreenChange);
};

export const mobileMediaQueryHandler = (handleScreenChange) => {
  const mobileMediaQuery = window.matchMedia("(max-width: 767px)");
  handleScreenChange(mobileMediaQuery); // Cek status awal
  mobileMediaQuery.addEventListener("change", handleScreenChange);
};

export const renderMobileWarning = () => {
  return (
    <span
      style={{
        color: "white",
        fontSize: "1.3rem",
        padding: "10px",
        textAlign: "center",
      }}
    >
      Untuk pengalaman terbaik, silakan akses melalui perangkat desktop atau
      laptop.
    </span>
  );
};

export const getAccessToken = () => {
  return localStorage.getItem(getTokenName());
};

export const setExamId = (examid) => {
  localStorage.setItem("examid", normalizeExamId(examid));
}

export const getExamId = () => {
  return localStorage.getItem("examid") === null
    ? "null"
    : localStorage.getItem("examid");
};

export const setMPId = (mpid) => {
  localStorage.setItem("mpid", mpid);
}

export const getMPId = () => {
  return localStorage.getItem("mpid") === null
    ? "null"
    : localStorage.getItem("mpid");
};

export const getAccessExamToken = () => {
  return localStorage.getItem(getExamTokenName());
};
export const getIframeToken = () => {
  return localStorage.getItem(getIframeTokenName());
};

export const getQuestionUrl = (question_list = [], question_id = 0) => {
  if (question_list.length === 0) {
    return "";
  }
  const q = question_list[question_id];
  return q || question_list[0];
};

export const telkomProktorURL = "https://console.amanin.id";
// export const telkomProktorURL = "http://localhost:3000";
// export const telkomProktorURL = "https://event.proktor.amanin.id/";

export const telkomPageURL = "https://console.amanin.id:10443";
// export const telkomPageURL = "http://localhost:5173";

export const isCorruptImages = (img) => {
  let base64Data = img.replace(/^data:image\/(jpeg|png);base64,/, "");
  const binaryString = atob(base64Data);
  const image = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    image[i] = binaryString.charCodeAt(i);
  }

  try {
    // Uji berbagai format gambar umum (misalnya JPEG, PNG)
    const isJPEG =
      image[0] === 0xff &&
      image[1] === 0xd8 &&
      image[image.length - 2] === 0xff &&
      image[image.length - 1] === 0xd9;
    const isPNG = image.toString("hex", 0, 4) === "89504e47";

    if (isJPEG || isPNG) {
      return false;
    }
    return true; // Tidak valid jika tidak dalam format yang dikena
  } catch (error) {
    return true; // Korup atau tidak valid
  }
};

const base64ToArrayBuffer = (base64) => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const isBlackImage = async (img) => {
  try {
    // Extract mime type and base64 data
    const base64Data = img.replace(/^data:image\/(jpeg|png);base64,/, "");
    // Convert base64 string to ArrayBuffer
    const arrayBuffer = base64ToArrayBuffer(base64Data);

    // Load image from ArrayBuffer
    const image = await Image.load(arrayBuffer);
    const { data } = image;
    const totalPixels = data.length / 4; // 4 = RGBA
    let darkPixels = 0;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Perceived brightness
      if (brightness < 25) {
        // Threshold for darkness
        darkPixels++;
      }
    }

    const darkRatio = (darkPixels / totalPixels) * 100;
    Debug(`RESULT darkRatio : ${darkRatio}`);

    return darkRatio > 90;
  } catch (error) {
    Error(error.message);
    return error.message;
  }
};

export const checkImage = async (img) => {
  try {
    const isCorruptImage = isCorruptImages(img);
    if (isCorruptImage) {
      return {
        status: false,
        message: Lang("Image is corrupt or invalid.", LANG(), {
          en: "Gambar rusak atau tidak valid.",
        }),
      };
    }

    const isBlackImages = await isBlackImage(img);
    if (isBlackImages) {
      return {
        status: false,
        message: Lang("Gambar kurang pencahayaan terlalu gelap.", LANG(), {
          en: "Underexposed images are too dark.",
        }),
      };
    }
    return {
      status: true,
      message: Lang("Gambar valid dan tidak gelap.", LANG(), {
        en: "Image is valid and not black.",
      }),
    };
  } catch (error) {
    console.error("Failed to fetch image:", error.message);
    return { status: false, errorMessage: error.message };
  }
};

let lastMessageTS = 0;
export const handleMessage = (callback, expectedAction, messageLog) => {
  const handleIframeMessage = (event) => {
    const message = event.data;

    if (message && message === expectedAction) {
      // if (message && (message === 'CopyPaste-Warning')) {

      if (Date.now() - lastMessageTS >= 5000) {
        lastMessageTS = Date.now();
        callback();
      }
    }
  };
  // Add a new event listener for receiving messages
  window.addEventListener("message", handleIframeMessage);

  return () => {
    // Clean up event listener when the component unmounts
    window.removeEventListener("message", handleIframeMessage);
  };
};

export const normalizeExamId = (id) => {
  // h5g-5kts-lo6
  let modifiedString = id.toUpperCase().replace(/-/g, "_");
  return modifiedString;
};

export const normalizeExamId2 = (id) => {
  // h5g-5kts-lo6
  let modifiedString = id.toLowerCase().replace(/_/g, "-");
  return modifiedString;
};

export const checkBlackDisplay = async (videoRef, callback = null) => {
  const canvas = document.createElement("canvas");
  canvas.width = videoRef.videoWidth;
  canvas.height = videoRef.videoHeight;
  const context = canvas.getContext("2d");

  context.drawImage(videoRef, 0, 0);
  const imageData = canvas.toDataURL("image/png");
  canvas.remove();

  if (callback) {
    const isBlackImages = await isBlackImage(imageData);
    callback(isBlackImages);
  }
};

export const setupInitialExamId = () => {
  // setup again localstorage examid
  const path = window.location.pathname;
  const pathSegments = path.split("/");
  if (pathSegments[1] === "id" && pathSegments[2]) {
    const id = pathSegments[2];
    setExamId(id);
  }
  if (pathSegments[3] === "mpid" && pathSegments[4]) {
    const mpid = pathSegments[4];
    setMPId(mpid);
  } else {
    localStorage.removeItem("mpid")
  }
  // end of setup
};

export const showMediaError = (mediaError) => {
  const me = [];
  for (let m in mediaError) {
    me.push(
      <div key={m}>
        <span className={classes.kind}>{m === "webrtc" ? "livefeed" : m}</span>
        <span className={classes.info}>{mediaError[m].toString()}</span>
      </div>
    );
  }

  return (
    mediaError &&
    Object.keys(mediaError).length > 0 && (
      <div className={classes.device_warning}>
        <div className={classes.info}>!!! Error Activating Your Device !!!</div>
        {me}
        <div className={classes.info}>
          Mohon untuk membaca troubleshooting di pojok kiri atas laman anda.
        </div>
      </div>
    )
  );
};

export const redirectOnLogin = (props, result = { as: "participant" }) => {
  const { history } = props;
  console.log("RESULT = ", result);
  if (result.as === "participant") {
    console.log("AS PARTICIPANT", `/exam/id/${normalizeExamId2(getExamId())}`)
    history.replace(`/exam/id/${normalizeExamId2(getExamId())}`);
  } else if (result.as === "proctorer") {
    console.log("AS PROCTORER");
    history.replace(`/proctor/id/${normalizeExamId2(getExamId())}`);
  }
}

export const getFullURL = () => {
  return `${window.location.protocol}//${window.location.host}`;
}
