import React from "react";
import classes from "./__index__.module.scss";
import {
  LANG,
  checkImage,
  getAccessToken,
  initializeMedias
} from "../../libs/utils";
import AuthContext from "../../store/auth-context";
import {
  fetchCurrentTs,
  fetchExamProfile,
  fetchSaveProctorFaceImage,
} from "../../libs/proctor_utils";
import MessageEvent from "../../libs/MessageEvent";
import { __Component__ } from "./Component";
import { __Action__ } from "./Action";
import { __ParticipantManager__ } from "./ParticipantManager";
import { __CommonWs__ } from "./CommonWs";
import { __ChatWs__ } from "./ChatWs";
import { __VideoRouter__ } from "./VideoRouter";
import { Lang } from "../../libs/Language";
import Tips from "../../components/Tips";
import { __FileWs__ } from "./FileWs";
import { ErrorAlert } from "../../components/Alert";
import { Debug, Err, Info } from "../../libs/logger";

const NEW_MESSAGE_KEY = "std::msg::recv";

class ProctorPage extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.messageEvent = new MessageEvent(this, NEW_MESSAGE_KEY);

    this.broadcastMessageLastTs = 0;

    this.participantContainerRef = React.createRef();
    this._component = new __Component__(this, classes);

    this._action = new __Action__(this);

    this._participantmanager = new __ParticipantManager__(this);
    this._commonws = new __CommonWs__(this);
    this._filews = new __FileWs__(this);
    this._chatws = new __ChatWs__(this);
    this._videorouter = new __VideoRouter__(this);

    this.state = {
      lang: LANG(),

      soundDetectedTs: {},

      newMessageState: [],

      showParticipantDesk: false,

      showControlBox: "normal", //normal, small
      showControlBoxState: "normal", //normal, close, small

      userMediaStream: null,
      participantNumber: 0,
      participants: null,

      broadcastMessage: [],
      broadcastToParticipantMessage: "",
      privateMessage: [],

      filterHideID: true,
      filterHideScreen: false,
      filterHideEmotion: false,
      filterShowOffline: true,
      enableMessageBeep: true,

      stateAI: {},
      // state variable, no state variable is above
      stateEmotion: {},

      isOpen: false,
      isLoading: false,

      isImageModalOpen: false,
      selectedImage: "",

      type: "",

      participantId: "",
      participantValidation: null,


      participantProfile: {
        id: "",
        name: "",
        major: ""
      },

      examProfile: {},

      preparationCountdown: {
        hours: "",
        minutes: "",
        seconds: "",
      },

      examCountDown: {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
      },

      examTime: {
        hours: "",
        minutes: "",
        seconds: ""
      },
      currentTs: 0,

      examStage: {
        title: "",
        time: ""
      },

      commonWsRegistered: false,
      connectingMessage: "",

      participantManagerStatus: false,
      fetchParticipantsStatus: false,

      participantManagerCommonWs: false,

      scaleParticipantVideo: 1,

      bgColor: '#E3B891',

      showMulticastSend: false,

      search: {
        isProcessing: false,
        searchText: "",
        requestedText: ""
      },

      listCommand: [],

      privateCallState: {
        duration: 0,
        participantId: "",
        participantName: "",
        callStatus: "hangup", //error/hangup/connecting/dialing/connected/
        errorMessage: ""
      }
    };

    this.fetchCurrentTsInterval = null;


    // commonws
    // this.commonWs = null;

    // chatws
    this.chatWs = null;
    this.chatWsRegistered = false;

    // Interval
    this.commonWSInterval = null;
    this.sendImageInterval = null;

    this.record = false;

    this.messageEvent.getNewMessage();

    console.log("PROCTORER ************ ACCESS=", getAccessToken());

  }

  //set background berdasarkan URL
  setBgColor() {
    const url = window.location.href;
    const hasSession = url.includes('pagi');

    if (hasSession) {
      this.setState({ bgColor: 'rgba(73, 147, 180, 0.5)' })
    }
  }

  componentWillUnmount() {
    this._component.componentWillUnmount()

    clearInterval(this.fetchCurrentTsInterval);
    clearInterval(this.clockInterval);
    clearInterval(this.commonWSInterval);
    clearInterval(this.sendImageInterval);
  }

  // processBroadcast(msg) {
  //   if (msg === undefined) {
  //     return [];
  //   }
  //   let data = msg.split("\n");
  //   let ch = [];
  //   for (const element of data) {
  //     let x = element.split(":");
  //     let j = { ts: x[0], from: x[1], text: x[2] }
  //     ch.push(j);
  //   }
  //   return ch;
  // }

  fetchCurrentTsRoutine = () => {
    fetchCurrentTs((error) => { }).then((data) => {
      this.setState({ currentTs: data.result })
    })

    if (this.chatWsRegistered === true) {
      if (this.chatWs !== null) {
        this.chatWs.sendGetBroadcastUpdate(this.context.profile.id, this.broadcastMessageLastTs);
      }
    }
  }

  commonWsIntervalRoutine = () => {
    this._commonws.commonWsRoutine();
    if (this.state.commonWsRegistered) {
      this._chatws.chatWsRoutine();
      this._action.updateTsRoutine();
    }

    // check aiwarning timeout
    const adjustStateAI = (stateAI) => {
      // let sAI = this.state.stateAI;
      let sAI = stateAI;
      let showAITime = this.state.examProfile.interprocess_ai_delay * 1000;
      for (let s in sAI) {
        if (Date.now() - sAI[s].ts > showAITime) {
          if (sAI[s].wt !== -1) {
            // reset the value first before delete it
            sAI[s] = {
              wt: -1,
              ts: Date.now()
            }
          } else {
            delete sAI[s];
            return;
          }
        }
      }
      this.setState({ stateAI: sAI });
    }
    adjustStateAI(this.state.stateAI);

    this._action.stateAIRoutine();

    // this.setState({ currentTs: this.state.currentTs + 1000 })
    // clockRoutine is already run above
    // this._action.clockRoutine();
  }

  sendImageIntervalRoutine = () => {
    if (this.record === false) {
      return;
    }
    if (this.context.profile.id.toLowerCase().includes("admin")) {
      Debug("IMAGE TIDAK DIREKAM")
      return;
    }
    // send image of proctorer to the server
    try {
      let els = document.getElementById("proctorerVideo");
      let canvas = document.createElement('canvas');

      canvas.width = 320;
      canvas.height = 240;

      let ctx = canvas.getContext('2d');
      ctx.drawImage(els, 0, 0, canvas.width, canvas.height);
      let image = canvas.toDataURL('image/jpeg');


      checkImage(image).then((response) => {
        if (response.status) {
          fetchSaveProctorFaceImage({
            id: this.context.profile.id,
            image: image,
          }, (err) => {
            console.error("[ERROR]", err);
          })
        }
      })
      // if (this.imageWs.sendProctorerImage(this.context.profile.id, image) === false) {
      //   this.imageWs.close();
      //   this.imageWs = null;
      // }

    } catch (e) {

    }

  }

  componentDidUpdate() {
    this._component.componentDidUpdate();
  }

  componentDidMount() {
    this.setState(prevState => ({
      connectingMessage: Lang("Mempersiapkan Protokol Komunikasi ...",
        prevState.lang,
        { en: `Preparing Communication Protocol ...` })
    })
    )
    this.setBgColor();

    fetchCurrentTs((error) => { }).then((data) => {
      this.setState({ currentTs: data.result })
    })

    clearInterval(this.fetchCurrentTsInterval);
    this.fetchCurrentTsInterval = setInterval(this.fetchCurrentTsRoutine, 60000);

    clearInterval(this.clockInterval)
    this.clockInterval = setInterval(this._action.clockRoutine, 1000)

    // fetchExamProfile
    fetchExamProfile((error) => {
      Err("error fetch exam", error);
    }).then(async (data) => {
      if (data !== null) {
        data = data.result;
        this.setState({ examProfile: data });

        initializeMedias(
          data.video_resolution,
          true,
          false,
          false,
          {
            face: true, // true means: initialized for the first time or it's error, so it's retried
            screen: false,
            webrtc: false
          },
          (stream) => {
            this.setState({ userMediaStream: stream });
          });
      } else {
        await ErrorAlert("Error to fetch exam profile");
      }
    })

    if (this.context.profile.id === undefined) {
      this._action.onLogout();
    }

    this._participantmanager.initParticipantManager();

    clearInterval(this.commonWSInterval);
    this.commonWSInterval = setInterval(() => {
      this.commonWsIntervalRoutine();

      this._videorouter.updateVideoServerRoutine();

      this._filews.filewsRoutine();

      if (this.context.profile.id.includes("proctoradmin")) {
        this._action.searchByProctorAdminRoutine();
      }


      // test the comm
      // this.commonWs.sendStartWebRTCMobilePairing(this.context.profile.id,
      //   '100002', `mp_${this.context.profile.id}_100002`, null
      // );
    }, 1000);


    clearInterval(this.sendImageInterval);
    this.sendImageInterval = setInterval(this.sendImageIntervalRoutine, 30000);


    // fetch Command List
    this._action.fetchCommandData();

  }

  render() {
    if (this.state.commonWsRegistered === false ||
      this.state.participantManagerCommonWs === false) {
      return this._component.renderConnectionLost();
    }
    return <>
      <Tips />
      {this._privatecall.renderOnCall()}
      {this._component.renderProctorPage()}
    </>
  }
}

export default ProctorPage;
