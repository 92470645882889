import { fetchJSONMessageConf } from "./proctor_utils";
import { fetchDataJSON } from "./utils";

// default duration in hour
export const DEFAULT_DURATION_INSTANT_EXAM = 48;

export const fetchTelUSSOLogin = async (error_handler) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: {
            redirect_url: "https://ai-proctoring.telkomuniversity.ac.id/redirect"
        }
    };
    return fetchDataJSON("https://api-gateway.telkomuniversity.ac.id/microsoft/issueauth",
        requestOptions,
        (error) => {
            error_handler(error);
        });
}

// export const fetchTelUSSOIssueProfile = async (token, error_handler) => {
//     const requestOptions = {
//         method: "GET",
//         headers: {
//             "Authorization": `Bearer ${token}`
//         },
//     }
//     return fetchDataJSON2("https://api-gateway.telkomuniversity.ac.id/issueprofile",
//         requestOptions,
//         (error) => {
//             error_handler(error);
//         });
// }

export const fetchTelUSSOCallback = async (token, error_handler) => {
    const body = {
        microsoft_token: token
    }
    return fetchDataJSON("https://api-gateway.telkomuniversity.ac.id/microsoft/callback",
        body,
        (error) => {
            error_handler(error);
        });
}

export const fetchTelULoginSSO = async (id, name, as, photo, error_handler) => {
    return fetchJSONMessageConf('telu-login-sso', {
        id: id,
        name: name,
        as: as,
        photo: photo,
    })
}

export const fetchTelULogin = async (examId, id, password, as, error_handler) => {
    return fetchJSONMessageConf('telu-login', {
        'exam-id': examId,
        'id': id,
        'password': password,
        'as': as
    }, error_handler)
}



export const fetchJoinExam = async (examId, id, password, error_handler) => {
    return fetchJSONMessageConf("join-exam", {
        'exam-id': examId,
        'id': id,
        'password': password
    }, error_handler)
}

export const fetchSavePhoto = async (id, photoUrl, as, error_handler) => {
    return fetchJSONMessageConf("save-photo", {
        id: id,
        photoUrl: photoUrl,
        as: as
    }, error_handler)
}


export const fetchExamStatistic = async (examId, error_handler) => {
    return fetchJSONMessageConf("get-exam-statistic-by-exam-id", {
        "exam-id": examId
    }, error_handler);
}

//Create Exam
export const fetchCreateExamProfileEms = async (body, error_handler) => {
    return fetchJSONMessageConf("create-exam-profile", { ...body }, error_handler);
}

export const generateLocalStorageId = (exam_id, local_id, remote_id) => {
    return `${exam_id}-${local_id}-${remote_id}`;
}

export const generateProctorerId = (exam_id, id) => {
    return `${exam_id}-${id}`
}

export const generateAlphanumeric = (length = 10) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const randomString = Array.from(Array(length), () => characters[Math.floor(Math.random() * characters.length)]).join('');
    const formattedString = randomString.substring(0, 3) + "-" + randomString.substring(3, 7) + "-" + randomString.substring(7);
    return formattedString;
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    // const options = { day: "numeric", month: "short", year: "numeric" };

    let day = date.getUTCDate();
    if (day < 10) {
        // return '0' + date.toLocaleDateString("en-GB", options);
        day = '0' + day;
    }

    let lmonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let month = lmonth[date.getUTCMonth()];

    let year = date.getFullYear();

    return day + ' ' + month + ' ' + year;
};


export const formatReverseDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

// DO NOT USE formattedDate, use formatDate instead
// export const formattedDate = () => {
//     // return new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
//     return formatDate(new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }))
// };

export const formattedTime = () => {
    return new Date()
        .toLocaleTimeString('en-GB', {
            hour: 'numeric', minute: 'numeric'
        }, { timeZone: 'UTC' });
}

export const getPreparationTime = (date, time) => {
    const now = new Date();
    const exam_time = new Date(`${date} ${time}`);
    const diff = exam_time.getTime() - now.getTime();
    const result = Math.floor(diff / (1000 * 60))

    // console.log(result)
    return result
}

export const fetchLogoutSSO = async (token, error_handler) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        },
        // mode: 'no-cors'
    }
    // return fetchDataJSON2('https://api-gateway.telkomuniversity.ac.id/issuerevoke',
    //     requestOptions,
    //     (error) => {
    //         error_handler(error)
    //     });
}

export const stopExam = async (examId, error_handler) => {
    return fetchJSONMessageConf("stop-exam", {
        id: examId
    }, error_handler);
}
export const startExam = async (examId, error_handler) => {
    return fetchJSONMessageConf("start-exam", {
        id: examId
    }, error_handler);
}

export const fetchUserPackageById = async (email, error_handler) => {
    return fetchJSONMessageConf("get-user-package", {
        email: email
    }, error_handler);
}

export const handleScreenChange = (_this, event) => {
    if (event.matches) {
        // console.log('Aplikasi sedang berjalan di perangkat mobile.');
        _this.setState({ desktopView: false })
    } else {
        // console.log('Aplikasi sedang berjalan di desktop.');
        _this.setState({ desktopView: true })
    }
};
