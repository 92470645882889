import { DecisionAlert, ErrorAlert, SuccessAlert } from "../../components/Alert";
import { Lang } from "../../libs/Language";
import ProktorAI from "../../libs/ProktorAI";
import { Debug, Err, Info } from "../../libs/logger";
import { fetchGetCommandChat, fetchParticipantByParticipantId, fetchProctorerProfileById, fetchSetAction, fetchUpdateValidationStatus, refreshTokenRoutine } from "../../libs/proctor_utils";
import { formatTime, getExamId, normalizeExamId2 } from "../../libs/utils";

export const __Action__ = function (_this) {
    _this.stateAIRoutineLastTs = 0;
    _this.storeParticipantImageInterval = null;

    this.searchByProctorAdminRoutineLastTs = 0;
    this.searchByProctorAdminRoutine = () => {
        if (Date.now() - this.searchByProctorAdminRoutineLastTs < 5000) {
            return;
        }
        const requestedText = _this.state.search.requestedText;
        this.searchByProctorAdminRoutineLastTs = Date.now();

        if (_this.state.search.isProcessing === true) {
            return;
        }

        if (_this.state.search.searchText !== requestedText) {
            _this.setState(prevState => ({
                search: {
                    ...prevState.search,
                    isProcessing: true
                }
            }))
            _this._participantmanager.fetchParticipantsData(requestedText, () => {
                setTimeout(() => {
                    // search here
                    // on done requested
                    _this.setState(prevState => ({
                        search: {
                            ...prevState.search,
                            isProcessing: false,
                            searchText: requestedText
                        }
                    }))
                    _this.pm.setSearchParam(requestedText);
                }, 500);
            })
        }
    }

    this.stateAIRoutine = () => {
        if (Date.now() - _this.stateAIRoutineLastTs < 30000) {
            return;
        }
        _this.stateAIRoutineLastTs = Date.now();
        const eAI = _this.stateEmotion;

        for (let i in _this.state.participants) {
            const p = _this.state.participants[i];
            if (p.status === 'online') {
                if (eAI[i] === undefined) {
                    eAI[i] = ProktorAI.warningAnalytic({
                        start: Date.now(),
                        data: [0, 0, 0, 0, 0]
                    })
                }
            }
        }

        for (let a in eAI) {
            if (_this.state.participants[a] && _this.state.participants[a]?.status === 'offline') {
                delete eAI[a];
            }
        }

        _this.stateEmotion = eAI;
        _this.setState({
            stateEmotion: { ...eAI }
        })
    }

    this.updateTsRoutine = () => {
        if (Date.now() - _this.updateTsRoutineLastTs < 30000) {
            return;
        }

        if (_this.commonWs !== null) {
            _this.commonWs.sendUpdateTs(_this.context.profile.id, _this.ipAddress, "proctorer");
        }

        this.fetchProctorerById();
    }

    this.fetchDetailParticipant = (participantId, participantUUId, type, videoElId) => {
        fetchParticipantByParticipantId(participantUUId === undefined ? participantId : participantUUId, (error) => {
            if (error) {
                Err("error fetch detail participant", error);
            }
        }).then((data) => {
            if (data?.result) {
                _this.setState({
                    isOpen: true,
                    isLoading: true,
                })

                data = data.result;
                let msg = _this.privateMessage.prepare(
                    `${_this.context.profile.id}-${participantId}`,
                    _this.context.profile.id,
                    '')

                _this.setState({
                    isOpen: true,
                    type: type,
                    participantId: participantId,
                    participantUUId: participantUUId,
                    participantProfile: data,
                    videoElId: videoElId,
                    privateMessage: msg,
                });

                setTimeout(() => {
                    _this.setState({
                        isLoading: false
                    })

                    // Debug("Load new message if any", msg, msg.length, msg[msg.length - 1]);
                    // if it's proctoradmin or the other proctors, this process will get all the data which is available on participants
                    _this.chatWs.getPrivateChat(participantId,
                        _this.context.profile.id,
                        msg.length > 0 ? msg[msg.length - 1].ts : -1
                    )
                }, 1000);

                clearInterval(_this.storePartipantImageInterval);
                _this.storeParticipantImageInterval = setInterval(() => {
                    if (_this.commonWs) {
                        _this.commonWs.sendStoreParticipantImage(participantId, _this.context.profile.id);
                    }
                }, /*1000*/ 5000);
            } else {
                ErrorAlert("Terjadi kesalahan mendapatkan data peserta " + participantId + ".")
            }
        })
    }

    this.fetchParticipantById = (participantUUId) => {
        fetchParticipantByParticipantId(participantUUId, (error) => {
            if (error) {
                Err("error fetch detail participant", error);
            }
        }).then((data) => {
            // Debug("GetDetailParticipant : ", data);
            if (data !== null) {
                data = data.result;
                _this.setState({ participantProfile: data });
            }
        })
    }

    this.fetchProctorerById = () => {
        fetchProctorerProfileById(_this.context.profile.id, (err) => {
            Err(err)
        }).then((v) => {
            if (v === null) {
                return;
            }
            Info(v);
            if (v.status === "success") {
                if ( !v.result ) {
                    Err("proctor is empty");
                    return;
                }
                if (v.result.action === "logout") {
                    fetchSetAction(_this.context.profile.id, "", (err) => {
                        Err(err);
                    }).then((v) => {
                        _this._action.onLogout();
                    })
                }
            }
        })
    }

    this.fetchCommandData = async () => {
        await fetchGetCommandChat({
            page: 0,
            alphabet: "",
            search: "",
            limit: 100,
        },
            (err) => {
                console.error("[ERROR]", err);
            }).then((response) => {
                if (response?.status === "success") {
                    // _this.listCommand = response.result
                    _this.setState({ listCommand: response.result });
                    Debug("COMMAND : ", response.result)
                }
            })
    }

    this.handleValidInvalid = async (valid, participantId = null) => {
        console.log("HANDLE VALID INVALID");
        let vElId = _this.state.videoElId;
        _this.setState({
            temporaryVideoElId: vElId,
            participantValidation: valid === "valid" ? "setuju" : "tolak",
            videoElId: "none"
        })

        if (_this.commonWs) {
            if (_this.commonWs.sendUpdateValidationStatus(
                _this.context.profile.id,
                participantId === null ? _this.state.participantId : participantId,
                valid) === false) {
                await ErrorAlert("Gagal meng-update validasi user, ulangi kembali.");
            }
        } else {
            await ErrorAlert("Gagal meng-update validasi user, ulangi kembali.");
        }

        // alert(`${_this.state.participantId} - ${_this.state.participantProfile.name} telah di set Valid`);
        // _this.showPopupDetailParticipant(_this.state.participantId, vElId);
    }

    this.updateValidationStatus = (participantUUId, validation_status) => {
        let result = {
            uuid: participantUUId,
            validation_status: validation_status
        }
        fetchUpdateValidationStatus(result, (error) => {
            if (error) {
                Err("error fetch detail participant", error);
            }
        }).then((response) => {
            if (response.status === "success") {
                this.fetchParticipantById(participantUUId)
            }
        })
    }

    this.handleBlocked = async (block, participant) => {

        if (block === "block") {
            // let text = window.confirm(`${Lang('Apakah Anda Yakin Ingin Mem-Block Peserta',
            //     _this.state.lang,
            //     { en: 'Are you sure to block' })} ${participant.id} - ${participant.name} ?`);
            let text = await DecisionAlert(`${Lang('Apakah Anda Yakin Ingin Mem-Block Peserta',
                _this.state.lang,
                { en: 'Are you sure to block' })} ${participant.id} - ${participant.name} ?`,
                (Lang("Peserta Berhasil Diblock!", _this.state.lang, { en: `Participant has been successfully blocked!` }))
            );

            if (!text) {
                return
            }
            this.updateValidationStatus(participant.uuid, 2)
        } else {
            SuccessAlert(`Unblocking Participant  ${participant.id} - ${participant.name}`)
            this.updateValidationStatus(participant.uuid, 0)
        }

        // alert(`${_this.state.participantId} - ${_this.state.participantProfile.name} telah di set Valid`);
        // _this.showPopupDetailParticipant(_this.state.participantId, vElId);
    }

    // handleInvalid = () => {
    //   Debug("Participant InValid");
    //   let vElId = _this.state.videoElId;
    //   _this.setState({ participantValidation: "tolak", videoElId: "none" })

    //   _this.commonWs.sendUpdateValidationStatus(_this.state.participantId, "invalid");

    //   alert(`${_this.state.participantId} - ${_this.state.participantProfile.name} telah di set Invalid`);
    //   _this.showPopupDetailParticipant(_this.state.participantId, vElId);
    // }

    this.handleCloseHelpButton = async (participantId, participantUUId) => {
        let profile = await fetchParticipantByParticipantId(participantUUId === undefined ? participantId : participantUUId, (error) => {
        }).then((data) => {
            if (data !== null) {
                return data.result;
            }
        })
        let answer = await DecisionAlert(`${Lang('Apakah anda yakin menghapus semua button bantuan', _this.state.lang, { en: 'Are you sure you want to delete all help buttons' })} ${profile.id} - ${profile.name} ?`, (Lang("Data Berhasil Dihapus!", _this.state.lang, { en: `Data has been successfully deleted.` })));

        if (_this.chatWs) {
            if (answer) {
                _this.chatWs.sendChat(_this.context.profile.id, "close-help-button", participantId);
            }
        }
    }

    this.onClose = () => {
        let dp = {
            id: '',
            name: '',
            major: ''
        }
        _this.setState({ participantValidation: null, videoElId: "none" })
        clearInterval(_this.storeParticipantImageInterval);
        _this.messageEvent.setNewMessage(_this.state.participantProfile.id, false);
        return _this.setState({ isOpen: false, participantProfile: dp })
    }

    this.onValidation = (participantId, participantUUId) => {
        _this.setState({
            privateMessage: _this.privateMessage.prepare(
                `${_this.context.profile.id}-${participantId}`,
                _this.context.profile.id,
                '')
        })
        this.fetchDetailParticipant(participantId, participantUUId, 'validation');
    }

    this.getDetailValidasiParticipant = (participantId) => {
        Debug("Participant ID : ", participantId);
    }

    this.handleChatBroadcast = () => {
        // broadcast-to-participant will be handled by commonws not chatws
        // in case there is a problem with chatws, broadcast still deliver the message
        // _this.commonWs.sendBroadcastToParticipant(_this.context.profile.id, "broadcast-to-participant", _this.inputRef.current.value);
        _this.commonWs.sendBroadcastToParticipantPost(
            _this.context.profile.id,
            "broadcast-to-participant",
            _this.inputRef.current.value,
            null,
            (v) => {
                if (v) {
                    SuccessAlert("Pesan Telah Terkirim");
                } else {
                    ErrorAlert("Pesan Gagal Terkirim");
                }
            });
        _this.inputRef.current.value = "";
        _this.inputRef.current.style.height = 'auto';
        _this.inputRef.current.style.height = `${Math.min(_this.inputRef.current.scrollHeight, 6 * 20)}px`;
        // if (_this.inputRef.current) {
        // }

        // _this.inputRef.current.value = "";
    }

    this.handleChatMulticast = () => {
        let par = _this.pm.getParticipants();
        let ts = Date.now();
        // let msg = _this.inputMulticastRef.current.value;
        for (let p in par) {
            // let id = `${_this.context.profile.id}-${p}`;

            // let message = _this.privateMessage.merge(
            //     id,
            //     ts,
            //     _this.context.profile.id,
            //     msg
            // )
            
            if (_this.chatWs) {
                _this.chatWs.sendChat(
                    _this.context.profile.id,
                    par[p].id,
                    JSON.stringify(_this.privateMessage.pack(
                        ts,
                        _this.context.profile.id,
                        _this.inputMulticastRef.current.value
                    )),
                    p
                )
            }
        }

        _this.inputMulticastRef.current.value = "";
        SuccessAlert("Mengirim Pesan ke semua peserta");

        _this.setState({ showMulticastSend: false })
    }

    this.clockRoutine = () => {
        refreshTokenRoutine()

        if (_this.state.currentTs === 0) {
            return;
        }

        // Menuju Persiapan Ujian
        const prepareCountDown = (_this.state?.examProfile?.preparation_time_ts * 1000) - _this.state.currentTs;

        // convert milliseconds to seconds, minutes, hours, and days
        const secondsP = Math.floor((prepareCountDown / 1000) % 60);
        const minutesP = Math.floor((prepareCountDown / 1000 / 60) % 60);
        const hoursP = Math.floor((prepareCountDown / (1000 * 60 * 60)) % 24);

        const prepareTimer = {
            hours: hoursP > 0 ? hoursP : 0,
            minutes: minutesP > 0 ? minutesP : 0,
            seconds: secondsP > 0 ? secondsP : 0,
        }

        // Menuju Waktu Ujian
        const examCountDown = (_this.state?.examProfile?.start_time_ts * 1000) - _this.state.currentTs;
        const daysE = Math.floor(examCountDown / (1000 * 60 * 60 * 24))
        const secondsE = Math.floor((examCountDown / 1000) % 60);
        const hoursE = Math.floor((examCountDown / (1000 * 60 * 60)) % 24);
        const minutesE = Math.floor((examCountDown / 1000 / 60) % 60);

        const examPreTimer = {
            days: daysE,
            hours: hoursE > 0 ? hoursE : 0,
            minutes: minutesE > 0 ? minutesE : 0,
            seconds: secondsE > 0 ? secondsE : 0,
        }

        // Saat Ujian
        const examTime = (_this.state?.examProfile?.end_time_ts * 1000) - _this.state.currentTs;
        const seconds = Math.floor((examTime / 1000) % 60);
        const minutes = Math.floor((examTime / 1000 / 60) % 60);
        const hours = Math.floor((examTime / (1000 * 60 * 60)) % 24);
        const examTimer = {
            hours: hours > 0 ? hours : 0,
            minutes: minutes > 0 ? minutes : 0,
            seconds: seconds > 0 ? seconds : 0,
        }

        _this.setState({
            currentTs: _this.state.currentTs + 1000,
            preparationCountdown: prepareTimer,
            examCountDown: examPreTimer,
            examTime: examTimer,
        });

        if ((_this.state.examProfile.preparation_time_ts * 1000) > _this.state.currentTs) {
            _this.setState({
                examStage: {
                    title: "Menuju Persiapan Ujian",
                    time: `${formatTime(_this.state.preparationCountdown.hours)}:${formatTime(_this.state.preparationCountdown.minutes)}:${formatTime(_this.state.preparationCountdown.seconds)}`
                }
            })
        } else if ((_this.state.examProfile.start_time_ts * 1000) > _this.state.currentTs) {
            _this.setState({
                examStage: {
                    title: "Menuju Waktu Ujian",
                    time: `${_this.state.examCountDown.days} Hari ${formatTime(_this.state.examCountDown.hours)}:${formatTime(_this.state.examCountDown.minutes)}:${formatTime(_this.state.examCountDown.seconds)}`
                }
            })
        } else if ((_this.state.examProfile.end_time_ts * 1000) > _this.state.currentTs) {
            _this.record = true;
            _this.setState({
                examStage: {
                    title: "Ujian Sedang Berlangsung",
                    time: `${formatTime(_this.state.examTime.hours)}:${formatTime(_this.state.examTime.minutes)}:${formatTime(_this.state.examTime.seconds)}`
                }
            })
        } else {
            _this.setState({
                examStage: {
                    title: "Ujian Telah Selesai",
                    time: ""
                }
            })
        }
    }

    this.onLogout = () => {
        // if (window.confirm("Apakah anda ingin keluar dari sistem ?")) {
        _this.context.logout();
        window.location.href = `/id/${normalizeExamId2(getExamId())}`;
        // }
    }

    this.onImageModalOpen = (imageUrl) => {
        return _this.setState({ isImageModalOpen: true, selectedImage: imageUrl })
    }
    this.onImageModalClose = () => {
        return _this.setState({ isImageModalOpen: false, selectedImage: "" })
    }

    this.handleSetActionLogout = (id) => {
        const action = "logout";
        try {
            if (window.confirm(`Yakin mengeluarkan user ${id} ini ?`)) {
                fetchSetAction(id, action, (err) => {
                    ErrorAlert(`Gagal mengirim action=${action}, silakan coba kembali.`)
                }).then((v) => {
                    if (v.status === 'success') {
                        SuccessAlert(`Berhasil mengirim sinyal ${action}.`);
                    } else {
                        ErrorAlert(v.errorMessage);
                    }
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

}