import React from "react";
import classes from "./__index__.module.scss";
import ProktorAI from "../../libs/ProktorAI";
import {
  detectBrowser,
  fetchCurrentTs,
  getScreenNumber,
  refreshTokenRoutine,
} from "../../libs/proctor_utils";
import AuthContext from "../../store/auth-context";
import Warning from "../../components/Warning";
import { __Validation__ } from "./Validation";
import { __Preparation__ } from "./Preparation";
import { __ChatWs__ } from "./ChatWs";
import { __CommonWs__ } from "./CommonWs/CommonWs";
import { __ChromeExtension__ } from "./ChromeExtension";
import { currentTsRoutine } from "./Ts";
import { __Action__ } from "./Action";
import { __Component__ } from "./Component";
import { __Warning__ } from "./Warning";
import { __FileWs__ } from "./FileWs";
import { __FileApi__ } from "./FileApi";
import { __DynamicValidation__ } from "./DynamicValidation/__index__";
import { Lang } from "../../libs/Language";
import { LANG, MOBILE_PAIRING_MODE, renderMobileWarning } from "../../libs/utils";
import { __Audio__ } from "./Audio";
import { Debug, Info } from "../../libs/logger";
import { __PrivateCall__ } from "./PrivateCall";
import { __PictureInPicture__ } from "./PictureInPicture/__index__";

// const DISABLE_FILEWS = false;
const ENABLE_DYNAMIC_VALIDATION = true;

class ExamPage extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    console.log("************** EXAM PAGE");

    this.getScreenNumberCounter = 0;
    this.firstTimeValidation = true;

    // 30 detik terlalu cepat untuk 4000 peserta
    // this.DEFAULT_IMAGE_TO_SERVER_DELAY = 30000;

    // buat kirim setiap 60 detik
    this.DEFAULT_IMAGE_TO_SERVER_DELAY = 60000;

    this.imageToServerDelay = this.DEFAULT_IMAGE_TO_SERVER_DELAY;

    this.socketInterval = null;

    // ProktorAI
    this.pai = null;
    this.commonWs = null;
    this.record = false;

    this.faceIdCounter = 0;

    this.controlFetchLastTs = 0;

    this.state = {
      lang: LANG(),

      videoResolution: {
        width: -1,
        height: -1
      },
      loadingFS: {
        mainText: "",
        subText: "",
        displayTextDuration: 3000
      },
      newMessageState: [],

      extensionInstalled: true,

      showControlBox: "normal", //normal, small
      showControlBoxState: "normal", //normal, close, small

      newMessageReceived: false,
      webRtcMediaStream: null,
      userMediaStream: null,
      displayMediaStream: null,

      initExam: true,
      validationInProgress: false,

      validationStateMessage: "",
      moreThanOneScreen: false,
      numberOfScreen: 0,

      eventState: {
        event: 'normal', //'warning', ''
        // event: 'warning',
        info: ['Usahakan wajah tetap terlihat di kamera.']
      },
      broadcastMessage: "",
      // broadcastToParticipantMessage: "",

      //Proctorer Broadcast
      broadcastFromProctorerMessage: "",

      // private chat
      privateMessage: "",
      privateProctorerId: [],

      commonWsRegistered: false,
      connectingMessage: "Inisialisasi Koneksi",

      faceWsRegistered: false,
      screenWsRegistered: false,

      initProktorAIStatus: false,
      fetchExamStatus: false,
      fetchParticipantStatus: false,

      objectState: {
        model: null,
        objectName: [],
      },

      outFrameState: false,

      participantProfile: {},

      examTimes: {
        hours: "",
        minutes: "",
        seconds: ""
      },

      examCountDown: {
        days: "",
        hours: "",
        minutes: "",
        seconds: ""
      },

      examProfile: {
        title: null,
        title_2: null,
        startTime: null,
        start_time_ts: null,
        preparationTime: null,
        preparation_time_ts: null,
        duration: null,
        durationTime: null,
        url: null,
        direct_exam: null,

        interprocess_ai_delay: null,
        redirect_on_preparation: null,
        time_warning: null,
        face_detection_on_validation_thresh: null,
        face_detection_on_exam_thresh: null,
        display_warning_on_exam_thresh: null,

        ice_number: null,
        show_warning: null,

        useExtension: false,

        hide_chat_help_button: false,
      },

      examStage: {
        title: "",
        time: ""
      },

      minimize: false,
      minimizeState: "normal", //normal, close
      displayTimer: "",

      tab: {
        info: "",
      },

      showAlert: false,
      helpAlert: false,
      onValidationDone: "",

      timesAlert: false,
      displayTimeAlertColor: "black",

      indicator: {
        commonws: "green",
        recording: "green",
        ai: "green",
        chatws: "green"
      },

      validationImage: false,

      progressStateAI: "undone", // undone, processing, done
      progressStateUser: "undone",
      progressStateConn: "undone",

      // troubleshooting
      troubleshooting: {
        show: false,
        chosen: 0
      },

      ipdAddress: "",

      errorCaptureStatus: {
        face: 0,
        screen: 0,
        webrtc: 0
      }
    };

    // should be initiated after this.state
    this._chatws = new __ChatWs__(this, classes);
    this._preparation = new __Preparation__(this, classes);
    this._commonws = new __CommonWs__(this);
    this._action = new __Action__(this, classes);
    this._validation = new __Validation__(this, classes)
    this._component = new __Component__(this, classes);
    this._chromeextension = new __ChromeExtension__(this, classes);
    this._warning = new __Warning__(this);

    this._filews = new __FileWs__(this);
    this._fileapi_ = new __FileApi__(this);

    this._dynamicvalidation = new __DynamicValidation__(this);

    this._audio = new __Audio__(this, classes);

    this._privatecall = new __PrivateCall__(this);

    this._pictureinpicture = new __PictureInPicture__(this);
  }

  componentWillUnmount() {
    clearInterval(this.socketInterval)
    clearInterval(this.faceIdInterval)
  }

  componentDidMount() {
    this.setState(prevState => ({
      connectingMessage: Lang("Mempersiapkan Modul AI ...", prevState.lang, { en: "Preparing AI Module ..." }),
      progressStateAI: "processing"
    }))
    this.pai = new ProktorAI(this.context.profile.id, this.context.profile.folder_rand, this.commonWs);
    this.pai.load().then((v) => {
      this._action.onAIPreparationDone();
    });

    this.browser = detectBrowser();
    fetchCurrentTs((error) => { }).then((data) => {
      this.currentTs = data.result;
    })

    clearInterval(this.socketInterval);
    this.socketInterval = setInterval(() => {
      if (this.webrtc['liveVideo'] !== null) {
        this.webrtc['liveVideo'].getStats();
      }

      // show face reference
      if (this.faceReferenceDebugEnable) {
        let e = document.getElementById("faceReference");
        if (e !== null) {
          e.src = this.pai.faceReference?.src;
        }
      }

      // call chromeExtension
      this._chromeextension.chromeExtensionRoutine();

      if (this.context.profile.id === undefined) {
        this._action.onLogout();
        clearInterval(this.socketInterval);
        return;
      } else {
        this._commonws.commonWsRoutine();
        if (this.state.commonWsRegistered) {
          this._chatws.chatWsRoutine();
          this._action.updateTsRoutine();
        }
      }

      if ((
        this.state.userMediaStream !== null &&
        (this.state.displayMediaStream !== null || this.mode === 'mobile')
      )
        ||
        // case for error but still be able to take the test
        (
          this.state.errorCaptureStatus.face > 2 || this.state.errorCaptureStatus.screen > 2
        )) {
        this._filews.fileWsRoutine();
        this._action.sendImageToServerRoutine(this.imageToServerDelay);
        if (this.state.validationInProgress === false) {
          this._action.mediaStreamRoutine();
        }
      } else {
        // Media streams are not ready yet
      }

      this.currentTs = this.currentTs + 1000;
      currentTsRoutine(this, this.currentTs);

      if (ENABLE_DYNAMIC_VALIDATION) {
        this._dynamicvalidation.robotStuff();
      }

      if (Date.now() - this.controlFetchLastTs > 5000) {
        // to prevent the fetch process is called repeatedly, because the timeout is smaller than fetch latency
        this.controlFetchLastTs = Date.now();
        refreshTokenRoutine()
        this._action.fetchParticipantRoutine()
      }

      this._privatecall.routine();

      this._pictureinpicture.routine();
    }, 1000);

    clearInterval(this.faceIdInterval);
    this.faceIdInterval = setInterval(() => {
      // Check dynamically if the login is first time login
      // and all of the condition for validation is valid
      // redirect user to validation page without pressing the button
      if (this.firstTimeValidation &&
        this.state.validationInProgress === false &&
        (
          this.state.participantProfile.validation_status === 0
          ||
          this.state.participantProfile.validation_status === 3 /* valid without device */
        )
        &&
        this._preparation.conditionOnFaceValidation() === true) {
        this._validation.onValidation();
      }

      // Screen Number Detection
      if (this.getScreenNumberCounter > 5) {
        this.getScreenNumberCounter = 0;
        getScreenNumber().then((n) => {
          this.setState({
            numberOfScreen: this.mode === "mobile" ? 1 : n
          });
        })
      } else {
        this.getScreenNumberCounter++;
      }

      if (this.faceIdCounter >= this.state.examProfile.interprocess_ai_delay &&
        this.state.commonWsRegistered === true &&
        this.record === true) {
        this.faceIdCounter = 1;
        this._warning.warningIdentificationRoutine();
      } else {
        this.faceIdCounter++;
      }
    }, 1000)

    if (this.state.initExam === false) {
      // this._action.sendDataToIframe();
      Debug("TEST IFRAME")
    }

  }

  render() {
    if (this.mode !== "web" && MOBILE_PAIRING_MODE === false) {
      return renderMobileWarning();
    }

    if (this.state.examProfile.useExtension) {
      if (this.state.extensionInstalled === false) {
        return <Warning
          title="Anda belum menginstall Ekstensi Proktor"
          // content="Mohon untuk menginstall Ekstensi Proktor ke Google Chrome anda terlebih dahulu."
          content={`Mohon untuk menginstall Ekstensi Proktor ke Google Chrome anda terlebih dahulu.

          Anda dapat menginstall Ekstensi Proktor melalui link berikut: https://chrome.google.com/webstore/detail/iloehcmieidbonccnmhmcnnhfdbmfadf.
          
          Apabila anda sudah menginstall, anda bisa cek apakah ekstensi sudah di aktifkan atau belum melalui chrome://extensions
                    `}
        />;
      }
    }

    if (this.state.commonWsRegistered === false) {
      // return this.renderConnectionLost();
      return <>
        {this._component.generateLoadingFullScreen()}
        {this._component.renderInit()}
      </>
    }

    if (this.state.initExam === true) {
      if (this.state.validationInProgress === true) {

        if (ENABLE_DYNAMIC_VALIDATION) {
          return <>
            {this._component.generateLoadingFullScreen()}
            {this._dynamicvalidation.render()}
          </>
        } else {
          return <>
            {this._component.generateLoadingFullScreen()}
            {this._component.renderOnValidation()}
          </>
        }

      } else {
        return <>
          {this._component.generateLoadingFullScreen()}
          {this._component.renderWaitingExam()}
        </>
      }
    }
    return <>
      {this._component.generateLoadingFullScreen()}
      {this._component.renderMainExam()}
    </>
  }
}

export default ExamPage;
