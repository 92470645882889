import { fetchJSONMessageConf } from "./proctor_utils"

export const fetchLoginOAUTH = async (body, error_handler) => {
    return fetchJSONMessageConf('login-oauth', { ...body }, error_handler)
}

export const fetchLogin = async (examId, email, password, as, error_handler) => {
    return fetchJSONMessageConf('first-user-login', {
        'exam-id': examId,
        'email': email,
        'password': password,
        'as': as
    }, error_handler)
}

export const fetchSignUp = async (email, password, fullname, error_handler) => {
    return fetchJSONMessageConf('first-user-signup', {
        'email': email,
        'password': password,
        'fullname': fullname,
    }, error_handler)
}

export const fetchStopExam = async (email, error_handler) => {
    return fetchJSONMessageConf('first-stop-exam', {
        'email': email,
    }, error_handler)
}

export const getPhoto = (photo) => {
    return (photo && photo !== "") ?
        photo :
        'https://amanin.id/photos/bradpitt.jpeg'
}