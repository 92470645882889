import React, { Component } from 'react';
import classes from "./__index__.module.scss";
import AuthContext from '../../store/auth-context';
import { __ActionMp__ } from './Action';
import { __Component__ } from './Component';
import { Info } from '../../libs/logger';
import { __CommonWs__ } from '../ExamPage/CommonWs/CommonWs';
import { __FileApi__ } from '../ExamPage/FileApi';
import { __Action__ } from '../ExamPage/Action';
import { getExamId, setupInitialExamId } from '../../libs/utils';

class MobilePairingPage extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            useFrontCamera: true,
            isSpinning: false,
            reverseSpin: false,
            isPortrait: false, // untuk mendeteksi orientasi
        };
        this.videoRef = React.createRef();
        this.stream = null;
        this.commonWs = null;

        // to enable send video to server
        this.record = true;
        this.DEFAULT_IMAGE_TO_SERVER_DELAY = 30000;

        this._actionmp = new __ActionMp__(this, classes);
        this._component = new __Component__(this, classes);
        this._commonws = new __CommonWs__(this);
        this._fileapi_ = new __FileApi__(this);
        this._action = new __Action__(this, classes);
    }

    componentDidMount() {
        setupInitialExamId();

        // Deteksi orientasi perangkat
        this.handleOrientationChange();
        window.addEventListener("resize", this.handleOrientationChange);

        this._actionmp.startVideoStream();

        clearInterval(this.commonwsInterval);
        this.commonwsInterval = setInterval(() => {
            this._commonws.commonWsRoutine();

            this._actionmp.sendImageToServerRoutine(30000)
        }, 1000)
    }

    componentWillUnmount() {
        //
        window.removeEventListener("resize", this.handleOrientationChange);
        this._actionmp.stopVideoStream();
    }

    handleOrientationChange = () => {
        const isPortrait = window.innerHeight > window.innerWidth;
        this.setState({ isPortrait });
    };

    render() {
        if (this.context.profile.id === undefined || this.context.profile.id === null) {
            window.location.href = `/id/${getExamId()}`
            return
        }

        return (
            <div className={classes.fullscreen_container}>
                <div className={classes.fullscreen_container_v}
                >
                    {/* Overlay saat portrait */}
                    {this.state.isPortrait && (
                        <div className={classes.overlay}>
                            <p className={classes.overlay_message}>
                                Please rotate your device to landscape mode.
                            </p>
                        </div>
                    )}

                    {/* Video Stream */}
                    <video
                        ref={this.videoRef}
                        id="mobilePairingVideo"
                        autoPlay
                        muted
                        className={classes.fullscreen_video}
                        playsInline
                    />
                </div>
                {/* Logout Button */}
                <button
                    className={classes.logout_button}
                    onClick={() => {
                        console.log('User logged out')
                        // Info('User logged out');
                        // window.location.href = '/logout'; // Redirect ke halaman logout
                    }}
                >
                    Logout
                </button>
                {/* <div>
                    {this._component.navbar()}
                </div> */}
            </div>
        );
    }
}

export default MobilePairingPage;
