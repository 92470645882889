import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { fetchLogin, fetchLoginOAUTH, fetchSignUp } from "../../libs/proktor_first_utils";
import { fetchCreateExamProfileEms, fetchJoinExam } from "../../libs/proctor_utils_ext";
import Alert, { ErrorAlert, SuccessAlert } from "../../components/Alert";
import { getExamId, normalizeExamId, redirectOnLogin, setExamId } from "../../libs/utils";
import { fetchGenerateRequired } from "../../libs/proctor_utils";

// const GOOGLE_CLIENT_ID = "833620407736-ilgarviq67sq2v8chi6nc7r98gild1uo.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID = "554916165593-u2sse677o7h43i7h2q3kdc1o88glov3i.apps.googleusercontent.com";

const FACEBOOK_CLIENT_ID = "1027396358495821";
const FACEBOOK_CLIENT_SECRET = "5b3b8b93c4f85600552961d55a518305";

const GITHUB_CLIENT_ID = "93bf84838ff4356fe7c5";
const GITHUB_CLIENT_SECRET = "c1c22e12cfb481415e84eb0a36e5f102d0a19240";

export const __Action__ = function (_this, classes) {
    this.onLoginOAUTH = (data) => {
        _this.setState({ showLoading: "Logging In ...", showLoginModal: false })
        setTimeout(() => {
            fetchLoginOAUTH(data, (error) => {
                console.error("error=", error);
            }).then((data) => {
                let profile = data.result;
                // profile = {
                //     id: 'deddihp@gmail.com',
                //     photo: 'https://amanin.id/photos/deddihp.jpg',
                //     name: 'Deddi Hariprawira',
                //     email: 'deddihp@gmail.com',
                //     package_name: 'unknown',
                //     running_exam: [],
                //     max_duration: 100,
                //     total_duration_today: 0,
                // }
                localStorage.setItem("profile", btoa(JSON.stringify(profile)))
                _this.setState({
                    userProfile: profile,
                    showLoginModal: false,
                })
            }).finally(() => { _this.setState({ showLoading: "" }) });
        }, 1000)
    }

    this.onFormGoogleAuth = () => {
        return <>
            <LoginSocialGoogle
                client_id={GOOGLE_CLIENT_ID}
                onLoginStart={() => {
                    _this.setState({ showLoading: "Authenticating ...", showLoginModal: false })
                }}
                onResolve={({ provider, data }) => {
                    this.onLoginOAUTH({
                        id: data.email,
                        name: data.name,
                        photo: data.picture
                    })
                }}
                onReject={(err) => {
                    // pop up window close
                    // console.log(err)
                    _this.setState({ showLoading: "" });
                }}
                scope="email profile"
            >
                <GoogleLoginButton
                    style={{
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px"
                    }}
                />
            </LoginSocialGoogle>
        </>
    }

    this.onFormFacebookAuth = () => {
        return <>
            <LoginSocialFacebook
                appId={FACEBOOK_CLIENT_ID}
                onResolve={({ provider, data }) => {
                    console.log(provider, data)
                    this.onLoginOAUTH({
                        id: data.email,
                        name: data.name,
                        photo: ""
                    })
                }}
                onReject={(err) => {
                    console.log(err)
                }}
                scope={"email public_profile"}
            >
                <FacebookLoginButton
                    style={{
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px"
                    }}
                />
            </LoginSocialFacebook>
        </>
    }

    this.handleCreateExam = (data) => {
        _this.setState({ showLoading: "Loading ...", showCreateExamModal: false })
        console.log("HANDLE CREATE EXAM = ", data);
        const examid = data.id;
        fetchCreateExamProfileEms(data, (error => {
            alert(error.message)
        })).then((response) => {
            if (response.status === "success") {
                setExamId(examid);
                fetchGenerateRequired().then((v) => {
                    this.directExam(response?.result?.id)
                })
            } else if (response.status === "failed") {
                ErrorAlert(response.errorMessage)
            }
        }).catch(error => {
            ErrorAlert(error.message)
        }).finally(() => {
            setTimeout(() => {
                _this.setState({ showLoading: "" })
            }, 2000);
        })
    }


    this.loginHandler = (data) => {
        if (data === undefined || data === null || data.status !== "success") {
            ErrorAlert("Username and Password did not match");
            return;
        }

        if (data.status === "success") {
            let profile = data.result;
            console.log("PROFILE = ", profile, data);
            profile.exam_id = _this.examId
            localStorage.setItem("profile", btoa(JSON.stringify(profile)))
            _this.setState({ userProfile: profile, showLoginModal: false })
            setTimeout(() => {
                // _this._termsandconditions.getData();
            }, 1000)
        } else {
            ErrorAlert(data.errorMessage);
        }
    }

    this.signUp = (email, password, fullname) => {
        _this.setState({ showLoading: "Signing Up ...", showLoginModal: false })
        setTimeout(() => {
            fetchSignUp(
                email,
                password,
                fullname,
                (error) => {
                    console.error("error=", error);
                }).then((data) => {
                    if (data.status !== "success") {
                        ErrorAlert(data.errorMessage);
                        return;
                    } else if (data.status === "success") {
                        SuccessAlert("User is registered successfully. Now you can login.")
                    }
                }).finally(() => {
                    _this.setState({ showLoading: "" })
                });
        }, 1000)
    }
    this.logingIn = (id, password) => {
        _this.setState({ showLoading: "Logging In ...", showLoginModal: false })
        setTimeout(() => {
            fetchLogin(
                _this.examId,
                id,
                password,
                _this.state.loginAs,
                (error) => {
                    console.error("error=", error);
                }).then((data) => {
                    console.log("***********FETCH LOGIN DATA = ", data);
                    this.loginHandler(data);
                }).finally(() => { _this.setState({ showLoading: "" }) });
        }, 1000)
    }

    this.joinExamHandler = (examid, data) => {
        console.log("JOIN EXAM HANDLER DATA = ", data);
        if (!data || (data && data.status !== "success")) {
            alert(data ? data.errorMessage : "Tidak menemukan Ujian");
            return;
        }
        const expiresIn = 3600 * 12;
        const expirationTime = new Date(
            new Date().getTime() + expiresIn * 1000
        );
        let result = data.result;
        setExamId(normalizeExamId(examid));

        if (result.as === "participant") {
            const profile = { ...result, id: result.email }
            _this.context.login(
                result.as,
                result.access_token,
                _this.state.userProfile.email,
                profile,
                getExamId(),
                expirationTime
            )
        } else {
            // as proctor
            console.log("RESULT = ", result);
            // for proctor always login as proctor00001

            // const profile = { ...result, id: result.email }
            const p = "proctor00001";
            const profile = { ...result, id: p, email: p }
            _this.context.login(
                result.as,
                result.access_token,
                _this.state.userProfile.email,
                profile,
                getExamId(),
                expirationTime
            )
        }
        redirectOnLogin(_this.props, result);
    }

    this.joinExam = (examId) => {
        if (examId !== "" && _this.state.userProfile) {
            _this.setState({ showLoading: "Loading ..." })
            setTimeout(() => {
                fetchJoinExam(
                    normalizeExamId(examId),
                    _this.state.userProfile.email,
                    _this.state.userProfile.password,
                    (error) => {
                        console.error("[error]", error);
                    }
                ).then((data) => {
                    this.joinExamHandler(examId, data);
                }).finally(() => {
                    _this.setState({ showLoading: "" })
                })
            }, 1000)
        } else if (!_this.state.userProfile && _this.state.enableJoin === true) {
            _this.setState({ showLoginModal: true })
        }
    }

    this.directExam = (examId) => {
        this.joinExam(examId);
    }

    this.nextPrev = (type) => {
        let p = _this.state.chosenImage;
        if (type === "prev") {
            p--;
            if (p < 0) {
                p = _this.descList.length - 1;
            }
        } else {
            p++;
            if (p >= _this.descList.length) {
                p = 0;
            }
        }
        _this.setState({
            chosenImage: p
        })
    }
}